import React, { useState } from 'react';
import { SimpleNav } from '../../component/SimpleNav';
import { Typography, Button, Box, Tabs, Tab } from '@mui/material';

const OSPTreatment = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'OSPT Treatment',
                text: 'Check out this OSPT treatment!',
                url: window.location.href,
            })
                .catch((error) => console.log('Error sharing', error));
        } else {
            console.log('Web Share not supported on this browser');
        }
    };
    return (
        <>
            <SimpleNav prevLocation={"/analyze/protocols/ospt"}>Treatment</SimpleNav>
            <Typography variant="h5" sx={{ color: '#704798', fontWeight: "bold", textAlign: "center", paddingTop: 2 }}>OSPT</Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold", textAlign: "center", paddingBottom: 2 }}>Oily, Sensitive, Pigmented, & Tight</Typography>
            <Box display="flex" justifyContent="center">
                <Button variant='outlined' onClick={handleShare} fullWidth sx={{ maxWidth: 350, }} >
                    اشتراک گزاری
                </Button>
            </Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="کلینیک" />
                <Tab label="خانه" />
            </Tabs>
            {value === 0 && <Box p={3}>
                <p><strong>ارتقای مراقبتی </strong><strong>&ndash;</strong><strong> هر سه هفته/یک ماه یک&zwnj;بار</strong></p>
                <p>C-Purity foam</p>
                <p>فوم C-Purity یک فرمول پاک&zwnj;کننده عمیق، چربی&zwnj;های اضافی را از بین می&zwnj;برد و ناخالصی&zwnj;های سطحی را برای پوستی تمیز، نرم و لطیف پاک می&zwnj;کند. محصول حاوی SLS نیست.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>آسکوربیل تترا ایزوپالمیتات: یک مشتق ویتامین C محلول در روغن. به عنوان یک آنتی اکسیدان و عامل سفید کننده قوی، با قابلیت ضد آکنه و ضد پیری عمل می کند.</li>
                    <li>بابونه مقطر: عصاره گل بابونه که از طریق تقطیر تولید می شود. یک هیدروسول تسکین دهنده ملایم است که برای طراوت و آرامش پوست استفاده می شود.</li>
                </ul>
                <p>Pre-peel conditioner</p>
                <p>آماده سازی پوست برای لایه برداری، پرکننده و لیزر درمانی. نرم کننده پیش لایه برداری برای آماده سازی پوست برای هر گونه درمان مانند لایه برداری، فیلر و لیزر استفاده می شود.</p>
                <p>با چربی زدایی پوست، پوست را برای درمان های لایه برداری آماده می کند و به پخش و نفوذ یکنواخت عوامل لایه بردار کمک می کند. نرم کننده پیش لایه برداری حاوی ماده ضد تحریک ShieldSkin است.</p>
                <h3>GlyPeel 50 &ndash; 5 دقیقه (به مرور زمان اگر شرایط پوست اجازه دهد، زمان اعمال می&zwnj;تواند بیشتر شود.)</h3>
                <p>محلول لایه بردار پوست ۵۰% اسید گلیکولیک با ShieldSkin</p>
                <p>&nbsp;pH&lt;1</p>
                <p>50 ml/ a.69 Fl Oz E.<br /> GlyPeel 50 یک لایه بردار موثر اسید گلیکولیک است که علائم قابل مشاهده پیری و آسیب مانند خطوط ریز، چین و چروک، لکه&zwnj;های ایجاد شده در اثر نور خورشید و رنگدانه&zwnj;های ناهموار را بهبود می&zwnj;بخشد. برای پوست&zwnj;های چرب و مشکل دار، به یکنواخت شدن بافت خشن کمک می&zwnj;کند. این ماده با لایه برداری لایه&zwnj;های خارجی و میانی پوست، جلوگیری از ضخیم شدن لایه خارجی کورنئوسیت و کاهش تجمع سلول&zwnj;های سطحی پوست عمل می&zwnj;کند. همچنین پوست مرده را از بین می&zwnj;برد تا بافت را اصلاح کند و تولید کلاژن جدید، الاستین و گلیکوزامینوگلیکان را تحریک کند تا پوستی صاف تر و محکم تر داشته باشد. فرمول حاوی غلظت بالای اسید گلیکولیک ۵۰% همراه با ShieldSkin ، یک ضد تحریک بالینی اثبات شده، برای کاهش خطر تحریک است.</p>
                <p>خنثی&zwnj;کننده لایه بردار</p>
                <p>محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده برای خنثی کردن اسیدهای باقی مانده</p>
                <p>خنثی کننده لایه&zwnj;بردار یک محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده است که برای خنثی کردن اسیدهای باقیمانده که ممکن است پس از استفاده از لایه برداری شیمیایی روی پوست باقی بماند، استفاده می شود. این محصول اجازه می دهد تا عمق لایه برداری را کنترل کنید و اطمینان حاصل کنید که اسید روی پوست پس از اتمام درمان خنثی می&zwnj;شود. خنثی کننده لایه برداری حاوی ShieldSkin ضد تحریک است.</p>
                <h3>شستشو</h3>
                <h3>آمپول کمپلکس AcNetrol (توصیه می شود از آمپول کامل با دستگاه الکتروپوریشن استفاده شود) یا آمپول کمپلکس AcNetrol به همراه مخلوط ترموژل (2/1) + CoolRelief (2/1) &ndash; ماساژ به مدت 10 دقیقه</h3>
                <p>AcNetrol Complex ترکیبی غلیظ و موثر از عوامل ضد التهابی است و برای درمان پوست&zwnj;های ملتهب و دارای اختلال و همچنین تنظیم تولید سبوم گاه به گاه استفاده می شود.</p>
                <p>- کاهش قرمزی و لک</p>
                <p>- کاهش تولید سبوم</p>
                <p>- کاهش تکثیر باکتری ها</p>
                <p>- آبرسان و تسکین دهنده</p>
                <h4>عناصر فعال</h4>
                <p>- ۶% نیاسینامید: ماده ای که به طور قابل توجهی ترشح سبوم را کاهش می دهد و دارای خواص ضد التهابی و ضد باکتریایی قوی است.</p>
                <p>- دی پتاسیم گلیسیریزات: یک عامل ضد التهابی به دست آمده از ریشه شیرین بیان. مطالعات نشان داده اند که آن را به کاهش قرمزی و سوزش که معمولاً با بیماری های مختلف پوستی مانند آکنه، روزاسه، پسوریازیس و برخی از انواع درماتیت مرتبط است، کمک می کند.</p>
                <p>- Oligopeptide-10: یک پپتید ضد میکروبی ثبت شده که فعالیت بسیار خوبی در برابر باکتری P. acne از خود نشان می دهد و می تواند جایگزین آنتی بیوتیک ها در درمان طولانی مدت آکنه شود.</p>
                <p>- کمپلکس عصاره مشتق از مواد گیاهی (عصاره سبوس Oryza Sativa، عصاره Bosswellia Serrata): با التهاب مبارزه می کند.</p>
                <p>- سدیم PCA: یک جزء طبیعی NMF (فاکتور مرطوب کننده طبیعی) و یک عامل آبرسان است.</p>
                <h3>KeratPeel (لایه بسیار نازک) - در اولین درمان استفاده نشود- صورت خود را شستشو ندهید</h3>
                <p>سالیسیلیک اسید یک اسید بتا هیدروکسی محلول در روغن است. دارای اثرات کراتولیتیک برای شکستن کراتین و اثرات کودولیتیک برای جلوگیری از مسدود شدن منافذ است.</p>
                <p>اسید سالیسیلیک را می توان با سایر لایه بردارها ترکیب کرد و برای درمان آکنه التهابی و علائم پس از آکنه استفاده کرد. ماهیت چربی دوست به آن اجازه می&zwnj;دهد تا به غدد چربی نفوذ کند.</p>
                <h3>ترکیب PuriMask (3/2) + CoolRelief (3/1) &ndash; 15 دقیقه. و شستشو</h3>
                <p>PuriMask یک ماسک تصفیه کننده برای درمان پوست&zwnj;های دارای اختلال و چرب است. PuriMask ضد عفونی می کند، قرمزی پوست را تسکین می دهد، با جوش زدن مبارزه می&zwnj;کند و تکثیر باکتری ها را کاهش می دهد.</p>
                <h4>عناصر فعال</h4>
                <p>اولئانولیک اسید: یک مهارکننده قوی فرآیندهای التهابی سلولی و محافظ اصلی سلول ها در برابر استرس اکسیداتیو است. ۵-&alpha;ردوکتاز برای مبارزه با هایپرسبوریا و کاهش تأثیر تستوسترون بر روی پوست مستعد آکنه.</p>
                <ul>
                    <li>Nordihydroguaiaretic Acid (NDGA) یک تنظیم&zwnj;کننده رشد سلولی است که هایپرکراتوز و التهاب را مهار می&zwnj;کند. همچنین به کنترل رشد باکتری کمک می&zwnj;کند.</li>
                    <li>عصاره بره موم: به دلیل خواص ضد باکتریایی و ضد التهابی که دارد روند بهبود زخم را تسریع می کند.</li>
                    <li>عصاره مریم گلی دارویی: از بروز عفونت های پوستی جلوگیری می کند.</li>
                    <li>آلانتوئین: عیوب را التیام می بخشد.</li>
                    <li>روغن برگ اکالیپتوس: عفونت های پوستی را درمان می&zwnj;کند.</li>
                    <li>گوگرد: باکتری ها و چربی را از بین می&zwnj;برد که به نوبه خود باعث کاهش افزایش منافذ پوستی می&zwnj;شود.</li>
                </ul>
                <p>&nbsp;</p>
                <h3>PolarMist</h3>
                <p>PolarMist با ترکیبی از عوامل تسکین&zwnj;دهنده قوی، توانایی&zwnj;های آرام&zwnj;بخش و ضد التهابی را فراهم می&zwnj;کند. برای استفاده در روز و شب به عنوان یک مرطوب کننده با استاندارد بالا مناسب است و برای پوست&zwnj;های حساس یا تحریک شده ایده&zwnj;آل است. این کرم بر اساس یک فناوری خنک کننده پیشرفته، اثر تسکین دهنده و خنک کننده ایجاد می&zwnj;کند و در حالی که پوست را مرطوب و آرام می&zwnj;کند، خاصیت ارتجاعی و استحکام را بهبود می&zwnj;بخشد و ظهور علائم پیری را به تاخیر می&zwnj;اندازد. PolarMist همچنین برای حمایت و مرطوب کردن پوست&zwnj;های دارای اختلال عالی است. این محصول برای تمام گروه&zwnj;های سنی مناسب است.</p>
                <h4>عناصر فعال</h4>
                <p>عصاره جو دوسر: جو دو سر می&zwnj;تواند پوست را تسکین دهد و صاف و تغذیه کند. عصاره جو دو سر برای انواع پوست و به خصوص برای پوست&zwnj;های حساس و پوست های خشک و پوسته پوسته شده توصیه می&zwnj;شود.</p>
                <p>پالمیتول تتراپپتید ۷: یک پپتید موثر که با تنظیم ترشح پایه IL-6 (اینترلوکین-۶) -سیتوکینی که بر التهاب تأثیر می&zwnj;گذارد- رشد سلولی، فعال شدن ژن و تکثیر را تنظیم می&zwnj;کند و باعث می&zwnj;شود پوست در برابر نور خورشید، آلودگی و محرک&zwnj;های شیمیایی تحمل بالاتری داشته باشد. بقای IL-6 با پیری طبیعی و پیری ناشی از اشعه ماوراء بنفش مرتبط است. بنابراین پالمیتول تتراپپتید ۷ نیز از پوست در برابر پیری زودرس محافظت می&zwnj;کند و اثرات آن را کاهش می&zwnj;دهد.</p>
                <p>هیالورونات سدیم: اسید هیالورونیک با خلوص بسیار بالا که آبرسانی عمیق را فراهم می&zwnj;کند. خواص رطوبت&zwnj;گیر قوی هیالورونات سدیم باعث حفظ آب می شود و نقش مخزن آب را ایفا می کند.</p>
                <p>MET &amp; Menthyl PCA &amp; Lactamide MEA: ترکیبی از مواد خنک&zwnj;کننده که گیرنده&zwnj;های TRMP8را فعال می&zwnj;کند و با یک پالس عصبی احساس خنکی را القا می&zwnj;کند.</p>
                <h3>ضد آفتاب VitaShield</h3>
                <p>ضد آفتاب MultiVit نسل جدید ضد آفتاب ضد پیری با فیلتر UV کپسوله شده و ویتامین&zwnj;های B, C, E است. این ضد آفتاب به طور موثر از پوست در برابر اشعه&zwnj;های UVA/UVB و IR ، اشعه&zwnj;هایی که به روش&zwnj;های مختلف باعث آسیب پوست می&zwnj;شوند، محافظت می&zwnj;کند.</p>
                <p>- در برابر اشعه های UVA/UVB/IR/نور مرئی و رادیکال&zwnj;های آزاد محافظت می&zwnj;کند.</p>
                <p>- مقاومت سلول را در شرایط تنش دمایی افزایش می&zwnj;دهد.</p>
                <p>- آبرسانی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- از آسیب ماکرومولکولی ماتریکس جلوگیری می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>UV-Pearls&reg; کپسوله شده: معروف به &laquo;عینک آفتابی&raquo; برای پوست. در حالی که فیلتر UV از تماس مستقیم آن با سطح پوست جلوگیری می کند، از پوست محافظت می&zwnj;کند. UV-Pearls کپسوله شده در برابر تنش دمایی مقاوم هستند.</p>
                <p>تیتانیوم دی اکسید (نانو): یک ضد آفتاب بسیار موثر است. این ماده از پوست در برابر طیف کامل تابش خورشیدی - UVA، UVB، نور مرئی و اشعه مادون قرمز - محافظت می&zwnj;کند تا حتی برای انواع پوست های حساس نیز دفاع کاملی در برابر آفتاب ایجاد کند. تیتانیوم دی اکسید محافظت قوی در برابر پیری و چین و چروک ایجاد می&zwnj;کند و در عین حال رنگ غیر یکسان پوست را همسان می&zwnj;کند.</p>
                <p>اکتوئین: یک ماده چند منظوره با فواید پوستی متعدد است. اکتوئین می&zwnj;تواند تولید سلول&zwnj;های آفتاب سوختگی و آسیب های ناشی از اشعه UVA را کاهش دهد. اکتوئین همچنین به عنوان یک مخزن رطوبت برای جلوگیری از از دست دادن آب در انواع پوست های خشک و حساس مانند درماتیت آتوپیک عمل می&zwnj;کند. این ماده با حفظ آب بدن، به کاهش علائم خشکی کمک می&zwnj;کند.</p>
                <p>ویتامین&zwnj;های C و E: در نقش آنتی اکسیدان</p>
                <p>نیاسینامید: این ماده که به عنوان ویتامین B3 نیز شناخته می شود، یک ترکیب روشن کننده پوست است که از انتقال ملانوزوم ها از ملانوسیت ها به کراتینوسیت ها جلوگیری می کند. ملانوزوم ها اندامک هایی هستند که حاوی رنگدانه ملانین هستند. نیاسینامید با مهار انتقال آنها به کراتینوسیت ها، رنگدانه های پوست را کاهش می دهد و اثر سفید کنندگی دارد. این جزء سیستم های بیولوژیکی مختلف و یک ماده مغذی ضروری برای بدن است.</p>

            </Box>}
            {value === 1 && <Box p={3}>

                <p><strong>صبح</strong></p>
                <p>C-Purity foam</p>
                <h3>آمپول کمپلکس AcNetrol</h3>
                <h3>LumiLac / IntenseGel</h3>
                <p>LumiLac به طور منحصر به فرد جوانسازی پوست را تسریع می&zwnj;کند و بافت را اصلاح می&zwnj;کند و در عین حال رنگ پوست و آبرسانی را بهبود می&zwnj;بخشد. اسید لاکتیک ۱۰ درصد با ShieldSkin به همه انواع پوست اجازه می&zwnj;دهد تا از این کرم AHA در تمام طول سال بدون مشکل استفاده کنند. این کرم بافتی سبک و بدون چربی دارد که به سرعت جذب پوست می&zwnj;شود و برای انواع پوست مناسب است.</p>
                <p>فواید:</p>
                <p>- ظاهر چین و چروک و خطوط ریز را کاهش می&zwnj;دهد.</p>
                <p>- باعث جابجایی سلولی و لایه برداری می&zwnj;شود.</p>
                <p>- آسیب ناشی از نور خورشید را ترمیم می&zwnj;کند و از ایجاد توده جلوگیری می&zwnj;کند.</p>
                <p>- تراکم و خاصیت ارتجاعی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- به کاهش تغییر رنگ و هایپرپیگمانتاسیون کمک می&zwnj;کند.</p>
                <p>- برای استفاده در اطراف ناحیه ظریف چشم بی&zwnj;خطر است.</p>
                <p>- تداخلی با سایر داروهای آرایشی یا پزشکی ندارد.</p>
                <h3>PolarMist</h3>
                <p>PolarMist با ترکیبی از عوامل تسکین&zwnj;دهنده قوی، توانایی&zwnj;های آرام&zwnj;بخش و ضد التهابی را فراهم می&zwnj;کند. برای استفاده در روز و شب به عنوان یک مرطوب کننده با استاندارد بالا مناسب است و برای پوست&zwnj;های حساس یا تحریک شده ایده&zwnj;آل است. این کرم بر اساس یک فناوری خنک کننده پیشرفته، اثر تسکین دهنده و خنک کننده ایجاد می&zwnj;کند و در حالی که پوست را مرطوب و آرام می&zwnj;کند، خاصیت ارتجاعی و استحکام را بهبود می&zwnj;بخشد و ظهور علائم پیری را به تاخیر می&zwnj;اندازد. PolarMist همچنین برای حمایت و مرطوب کردن پوست&zwnj;های دارای اختلال عالی است. این محصول برای تمام گروه&zwnj;های سنی مناسب است.</p>
                <h4>عناصر فعال</h4>
                <p>عصاره جو دوسر: جو دو سر می&zwnj;تواند پوست را تسکین دهد و صاف و تغذیه کند. عصاره جو دو سر برای انواع پوست و به خصوص برای پوست&zwnj;های حساس و پوست های خشک و پوسته پوسته شده توصیه می&zwnj;شود.</p>
                <p>پالمیتول تتراپپتید ۷: یک پپتید موثر که با تنظیم ترشح پایه IL-6 (اینترلوکین-۶) -سیتوکینی که بر التهاب تأثیر می&zwnj;گذارد- رشد سلولی، فعال شدن ژن و تکثیر را تنظیم می&zwnj;کند و باعث می&zwnj;شود پوست در برابر نور خورشید، آلودگی و محرک&zwnj;های شیمیایی تحمل بالاتری داشته باشد. بقای IL-6 با پیری طبیعی و پیری ناشی از اشعه ماوراء بنفش مرتبط است. بنابراین پالمیتول تتراپپتید ۷ نیز از پوست در برابر پیری زودرس محافظت می&zwnj;کند و اثرات آن را کاهش می&zwnj;دهد.</p>
                <p>هیالورونات سدیم: اسید هیالورونیک با خلوص بسیار بالا که آبرسانی عمیق را فراهم می&zwnj;کند. خواص رطوبت&zwnj;گیر قوی هیالورونات سدیم باعث حفظ آب می شود و نقش مخزن آب را ایفا می کند.</p>
                <p>MET &amp; Menthyl PCA &amp; Lactamide MEA: ترکیبی از مواد خنک&zwnj;کننده که گیرنده&zwnj;های TRMP8را فعال می&zwnj;کند و با یک پالس عصبی احساس خنکی را القا می&zwnj;کند.</p>
                <h3>ضد آفتاب VitaShield</h3>
                <p>ضد آفتاب MultiVit نسل جدید ضد آفتاب ضد پیری با فیلتر UV کپسوله شده و ویتامین&zwnj;های B, C, E است. این ضد آفتاب به طور موثر از پوست در برابر اشعه&zwnj;های UVA/UVB و IR ، اشعه&zwnj;هایی که به روش&zwnj;های مختلف باعث آسیب پوست می&zwnj;شوند، محافظت می&zwnj;کند.</p>
                <p>- در برابر اشعه های UVA/UVB/IR/نور مرئی و رادیکال&zwnj;های آزاد محافظت می&zwnj;کند.</p>
                <p>- مقاومت سلول را در شرایط تنش دمایی افزایش می&zwnj;دهد.</p>
                <p>- آبرسانی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- از آسیب ماکرومولکولی ماتریکس جلوگیری می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>UV-Pearls&reg; کپسوله شده: معروف به &laquo;عینک آفتابی&raquo; برای پوست. در حالی که فیلتر UV از تماس مستقیم آن با سطح پوست جلوگیری می کند، از پوست محافظت می&zwnj;کند. UV-Pearls کپسوله شده در برابر تنش دمایی مقاوم هستند.</p>
                <p>تیتانیوم دی اکسید (نانو): یک ضد آفتاب بسیار موثر است. این ماده از پوست در برابر طیف کامل تابش خورشیدی - UVA، UVB، نور مرئی و اشعه مادون قرمز - محافظت می&zwnj;کند تا حتی برای انواع پوست های حساس نیز دفاع کاملی در برابر آفتاب ایجاد کند. تیتانیوم دی اکسید محافظت قوی در برابر پیری و چین و چروک ایجاد می&zwnj;کند و در عین حال رنگ غیر یکسان پوست را همسان می&zwnj;کند.</p>
                <p>اکتوئین: یک ماده چند منظوره با فواید پوستی متعدد است. اکتوئین می&zwnj;تواند تولید سلول&zwnj;های آفتاب سوختگی و آسیب های ناشی از اشعه UVA را کاهش دهد. اکتوئین همچنین به عنوان یک مخزن رطوبت برای جلوگیری از از دست دادن آب در انواع پوست های خشک و حساس مانند درماتیت آتوپیک عمل می&zwnj;کند. این ماده با حفظ آب بدن، به کاهش علائم خشکی کمک می&zwnj;کند.</p>
                <p>ویتامین&zwnj;های C و E: در نقش آنتی اکسیدان</p>
                <p>نیاسینامید: این ماده که به عنوان ویتامین B3 نیز شناخته می شود، یک ترکیب روشن کننده پوست است که از انتقال ملانوزوم ها از ملانوسیت ها به کراتینوسیت ها جلوگیری می کند. ملانوزوم ها اندامک هایی هستند که حاوی رنگدانه ملانین هستند. نیاسینامید با مهار انتقال آنها به کراتینوسیت ها، رنگدانه های پوست را کاهش می دهد و اثر سفید کنندگی دارد. این جزء سیستم های بیولوژیکی مختلف و یک ماده مغذی ضروری برای بدن است.</p>
                <h3>SunShield برای پوست&zwnj;های چرب (در طول روز با هر بار قرار گرفتن در معرض نور خورشید)</h3>
                <p>پودر معدنی ضد آفتاب با طیف وسیع فرموله شده برای پاسخگویی به نیازهای پوست&zwnj;های دارای اختلال و چرب که برای استفاده روزمره طراحی شده است، پودر معدنی SunShield SPF 50 محافظت فوری و بهینه را در برابر اشعه های مضر UVA، UVB و IR ارائه می&zwnj;کند. این پودر با خواص ضد التهابی خود، راه حل ایده آلی برای محافظت در برابر آفتاب برای پوست&zwnj;های دارای اختلال و چرب است. همچنین به جلوگیری از آسیب&zwnj;های ناشی از نور خورشید مانند پیری و اختلالات رنگدانه&zwnj;ای کمک می&zwnj;کند.</p>
                <h4>مزایای SunShield:</h4>
                <ul>
                    <li>از اشعه های مضر UVA/UVB/IR و رادیکال های آزاد محافظت می&zwnj;کند.</li>
                    <li>خاصیت ضد التهابی ارائه می&zwnj;دهد.</li>
                    <li>به جلوگیری از آسیب های ناشی از نور خورشید، از جمله پیری و اختلالات رنگدانه&zwnj;ای کمک می&zwnj;کند.</li>
                    <li>بسته بندی با کاربری آسان امکان کنترل مقدار پودر را در حین استفاده فراهم می&zwnj;کند.</li>
                    <li>بسته بندی سازگار با محیط زیست - برس و ظرف پودر قابل تعویض</li>
                    <li>موجود در دو رنگ متفاوت</li>
                    <li>غیر حساس به گرما</li>
                    <li>پوشش مات، پوشش بدون درز، اثر بازتابی</li>
                </ul>
                <p><strong>عصر</strong></p>
                <p>C-Purity foam</p>
                <p>محلول AcNetrol</p>
                <h3>LumiLac / IntenseGel</h3>
                <h3>PolarMist</h3>
                <p>⚠ Derma white را عصرها پس از LumiLac اضافه کنید: Derma white را به مرور و یک روز در میان زمان&zwnj;هایی که پوست به خوبی سازگار شده است اعمال کنید.</p>
                <p><strong>هفتگی</strong></p>
                <p>PuriMask تا دو بار در هفته</p>
                <p>&nbsp;</p>
            </Box>}
        </>
    );
};

export default OSPTreatment;