import React, { useState } from 'react';
import { SimpleNav } from '../../component/SimpleNav';
import { Typography, Button, Box, Tabs, Tab } from '@mui/material';

const OSNWTreatment = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'OSNW Treatment',
                text: 'Check out this OSNW treatment!',
                url: window.location.href,
            })
                .catch((error) => console.log('Error sharing', error));
        } else {
            console.log('Web Share not supported on this browser');
        }
    };
    return (
        <>
            <SimpleNav prevLocation={"/analyze/protocols/osnw"}>Treatment</SimpleNav>
            <Typography variant="h5" sx={{ color: '#704798', fontWeight: "bold", textAlign: "center", paddingTop: 2 }}>OSNW</Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold", textAlign: "center", paddingBottom: 2 }}>Oily, Sensitive, Non-Pigmented, & Wrinkled</Typography>
            <Box display="flex" justifyContent="center">
                <Button variant='outlined' onClick={handleShare} fullWidth sx={{ maxWidth: 350, }} >
                    اشتراک گزاری
                </Button>
            </Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="کلینیک" />
                <Tab label="خانه" />
            </Tabs>
            {value === 0 && <Box p={3}>
                <p><strong>ارتقای اول: مراقبت </strong><strong>&ndash;</strong><strong> هر سه هفته/یک ماه یک&zwnj;بار</strong></p>
                <p>C-Purity foam</p>
                <p>فوم C-Purity یک فرمول پاک&zwnj;کننده عمیق، چربی&zwnj;های اضافی را از بین می&zwnj;برد و ناخالصی&zwnj;های سطحی را برای پوستی تمیز، نرم و لطیف پاک می&zwnj;کند. محصول حاوی SLS نیست.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>آسکوربیل تترا ایزوپالمیتات: یک مشتق ویتامین C محلول در روغن. به عنوان یک آنتی اکسیدان و عامل سفید کننده قوی، با قابلیت ضد آکنه و ضد پیری عمل می کند.</li>
                    <li>بابونه مقطر: عصاره گل بابونه که از طریق تقطیر تولید می شود. یک هیدروسول تسکین دهنده ملایم است که برای طراوت و آرامش پوست استفاده می شود.</li>
                </ul>
                <p>Pre-peel conditioner</p>
                <p>آماده سازی پوست برای لایه برداری، پرکننده و لیزر درمانی. نرم کننده پیش لایه برداری برای آماده سازی پوست برای هر گونه درمان مانند لایه برداری، فیلر و لیزر استفاده می شود.</p>
                <p>با چربی زدایی پوست، پوست را برای درمان های لایه برداری آماده می کند و به پخش و نفوذ یکنواخت عوامل لایه بردار کمک می کند. نرم کننده پیش لایه برداری حاوی ماده ضد تحریک ShieldSkin است.</p>
                <p>GlyPeel 50 &ndash; 5 الی6 دقیقه &ndash; شستشو ندهید (به مرور زمان اگر شرایط پوست اجازه دهد، زمان اعمال می&zwnj;تواند بیشتر شود.)</p>
                <p>محلول لایه بردار پوست ۵۰% اسید گلیکولیک با ShieldSkin</p>
                <p>&nbsp;pH&lt;1</p>
                <p>50 ml/ a.69 Fl Oz E.<br /> GlyPeel 50 یک لایه بردار موثر اسید گلیکولیک است که علائم قابل مشاهده پیری و آسیب مانند خطوط ریز، چین و چروک، لکه&zwnj;های ایجاد شده در اثر نور خورشید و رنگدانه&zwnj;های ناهموار را بهبود می&zwnj;بخشد. برای پوست&zwnj;های چرب و مشکل دار، به یکنواخت شدن بافت خشن کمک می&zwnj;کند. این ماده با لایه برداری لایه&zwnj;های خارجی و میانی پوست، جلوگیری از ضخیم شدن لایه خارجی کورنئوسیت و کاهش تجمع سلول&zwnj;های سطحی پوست عمل می&zwnj;کند. همچنین پوست مرده را از بین می&zwnj;برد تا بافت را اصلاح کند و تولید کلاژن جدید، الاستین و گلیکوزامینوگلیکان را تحریک کند تا پوستی صاف تر و محکم تر داشته باشد. فرمول حاوی غلظت بالای اسید گلیکولیک ۵۰% همراه با ShieldSkin ، یک ضد تحریک بالینی اثبات شده، برای کاهش خطر تحریک است.</p>
                <p>خنثی&zwnj;کننده لایه بردار</p>
                <p>محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده برای خنثی کردن اسیدهای باقی مانده</p>
                <p>خنثی کننده لایه&zwnj;بردار یک محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده است که برای خنثی کردن اسیدهای باقیمانده که ممکن است پس از استفاده از لایه برداری شیمیایی روی پوست باقی بماند، استفاده می شود. این محصول اجازه می دهد تا عمق لایه برداری را کنترل کنید و اطمینان حاصل کنید که اسید روی پوست پس از اتمام درمان خنثی می&zwnj;شود. خنثی کننده لایه برداری حاوی ShieldSkin ضد تحریک است.</p>
                <p>شستشو</p>
                <h3>کمپلکس پپتیدی لیفت و سفید کننده (توصیه می شود از آمپول کامل با دستگاه الکتروپوریشن استفاده شود) یا 3/1 از آمپول کمپلکس پپتیدی لیفت و سفیدکننده به همراه مخلوط ترموژل (2/1) + CoolRelief (2/1) &ndash; ماساژ به مدت 10 دقیقه و سپس شستشو</h3>
                <p>کمپلکس پپتیدی لیفت و سفید کننده یک ترکیب موثر و بسیار غلیظ از پپتیدها است که برای دستیابی به اثر قابل توجهی در ثبات و سفیدی پوست استفاده می شود.</p>
                <p>- مسیرهای بازسازی متعدد در درم را فعال می کند.</p>
                <p>- عمق چین و چروک را به میزان قابل توجهی کاهش می دهد.</p>
                <p>- سطح رطوبت و تورم پوست را بهبود می بخشد.</p>
                <p>- سطح تغذیه و خاصیت ارتجاعی پوست را افزایش می دهد.</p>
                <p>- اثر سفید کنندگی پوست را فراهم می کند.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>Palmitoyl Tripeptide-38 (Matrixyl Synthe'6TM): این فرمول حاوی غلظت بسیار بالایی از Matrixyl Synthe'6 است و یک تقویت کننده سفت کننده پوست در نظر گرفته می شود. این فرمول یک پپتید تقلیدی ماتریکین است که با سنتز ۶ جزء ساختاری اصلی درم، باعث تسریع روند پر کردن چین و چروک می&zwnj;شود. مواد سازنده را با تحریک سنتز شش مولکول اصلی - اسید هیالورونیک، کلاژن I، III و IV، لامینین ها و فیبرونکتین فراهم می&zwnj;کند و به طور قابل توجهی پوست را سفت می&zwnj;کند و چین و چروک ها را پر می&zwnj;کند.</li>
                    <li>سیکلوتتراپپتید-۲۴ آمینو سیکلوهگزان کربوکسیلات: یک ساختار حلقوی منحصر به فرد که با چین و چروک و خطوط ریز مقابله می&zwnj;کند. طراحی منحصر به فرد آن به عنوان یک پپتید هوشمند و انتخابی، فرآیندهای طبیعی ارتباط و ترمیم پوست را تقلید می کند.</li>
                    <li>اُلیگوپپتید-68: عامل سفید کننده نوآورانه و منحصر به فرد که ظاهر لکه های تیره و تغییر رنگ پوست را ناپیداتر می&zwnj;کند.</li>
                    <li>عصاره Swertia Chirata: یک ماده تشکیل شده از برگ&zwnj;های جنتیان هندی است. این عصاره سلول های بنیادی مشتق شده از چربی را فعال کرده، تولید فاکتورهای رشد کراتینوسیت را تقویت می&zwnj;کند و قادر به تحریک تکثیر کراتینوسیت&zwnj;ها برای بازسازی اپیدرم نازک و بهبود ظاهر پوست چروکیده است. همچنین باعث بهبود ظاهر چین و چروک&zwnj;های عمودی و کاهش چین و چروک&zwnj;های لب می&zwnj;شود.</li>
                    <li>هیالورونات سدیم: اسید هیالورونیک با وزن مولکولی بالا با خلوص بسیار بالا که آبرسانی عمیق را فراهم می کند. خاصیت رطوبت سنجی قوی هیالورونات سدیم باعث حفظ آب در سطح پوست می شود و نقش مخزن آب را ایفا می کند.</li>
                    <li>سدیم PCA: یک جزء طبیعی NMF (عامل مرطوب کننده طبیعی). سدیم PCA بسیار جاذب آب است و می&zwnj;تواند چندین برابر وزن خود را در آب نگه دارد، بنابراین به کاهش از دست دادن رطوبت از طریق تبخیر (از دست دادن آب ترانس اپیدرمی) کمک می&zwnj;کند.</li>
                </ul>
                <h3>KeratPeel (لایه نازک) - صورت را شستشو ندهید.</h3>
                <p>سالیسیلیک اسید یک اسید بتا هیدروکسی محلول در روغن است. دارای اثرات کراتولیتیک برای شکستن کراتین و اثرات کودولیتیک برای جلوگیری از مسدود شدن منافذ است.</p>
                <p>اسید سالیسیلیک را می توان با سایر لایه بردارها ترکیب کرد و برای درمان آکنه التهابی و علائم پس از آکنه استفاده کرد. ماهیت چربی دوست به آن اجازه می&zwnj;دهد تا به غدد چربی نفوذ کند.</p>
                <p>ماسک ProLift &ndash; 15 دقیقه</p>
                <p>ماسک ProLift یک محصول مراقبت از پوست ضد پیری پیشرفته است که بلافاصله پوست را لیفت و سفت می کند. نتایج سریع ضد چین و چروک را ارائه می دهد و در عین حال پوست را آبرسانی، نرم و آرام می کند. تنها ۵ دقیقه پس از استفاده از آن، پوست شما شاداب به نظر می رسد.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>استیل دی پپتید-۱ ستیل استر: یک ماده فعال پپتیدی ملایم و آرامش بخش در لوازم آرایشی است. این اولین ماده در نوع خود است که به پوست سیگنال کاهش تنش می&zwnj;دهد. ترشح اندورفین را تحریک می&zwnj;کند و اثری خوشایند بر روی پوست ایجاد می&zwnj;کند. همچنین از انقباضات ماهیچه ای که باعث بروز چین و چروک و خطوط می شود جلوگیری می&zwnj;کند.</li>
                    <li>پروتئین کنجد هیدرولیز شده PG - پروپیل متیل سیلاندیول: یک ماده صاف کننده نسل جدید است. حاوی یک عامل سفت کننده فوری جدید است که بلافاصله پوست را صاف و لیفت می&zwnj;کند. علاوه بر یک سفت کننده سریع، این ماده پوست را عمیقاً آبرسانی می&zwnj;کند. در یک لحظه خطوط ریز و چین و چروک ها را صاف می&zwnj;کند و در عین حال پوست را کاملا مرطوب می&zwnj;کند.</li>
                    <li>آلانتوئین: یک ماده فعال است که از پوست محافظت می&zwnj;کند و آن را تسکین می&zwnj;دهد و به طور طبیعی روند بهبود پوست را تحریک می کند.</li>
                    <li>شی باتر: حاوی غلظت بالایی از ویتامین&zwnj;ها و اسیدهای چرب طبیعی است که آن را به طور باورنکردنی مقوی و مرطوب کننده برای پوست می کند.</li>
                </ul>
                <p>&nbsp;</p>
                <h4>سرم فیلر عمیق</h4>
                <p>این سرم ضد چین و چروک، تراکم، استحکام و قابلیت ارتجاعی پوست را بهبود می&zwnj;بخشد و در عین حال آبرسانی عمیقی دارد. از ساخت و جوانسازی پوست که از طریق روش&zwnj;هایی مانند لایه برداری، میکرونیدلینگ، RF و لیزر انجام می شود، پشتیبانی می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>MG6P: این سرم حاوی ترکیبات فعالی است که با همکاری یکدیگر باعث افزایش کلاژن و الاستین در پوست می&zwnj;شوند. MG6P به سلول های پوست انرژی می&zwnj;دهد تا تولید این پروتئین&zwnj;های کلیدی را افزایش دهد. اسیدهای آمینه پرولین و لیزین بلوک&zwnj;های سازنده برای ساخت کلاژن و الاستین هستند. مس به تثبیت ساختارهای کلاژن و الاستین کمک می&zwnj;کند. این مواد با هم پوست را تحریک می&zwnj;کنند+ تا فیبرهای کلاژن و الاستین بیشتری را سنتز کند که استحکام، کشش و اثرات ضد پیری را بهبود می&zwnj;بخشد.</p>
                <p>Tetradecyl Aminobutyroylvalylaminobutyric Urea Trifluoroacetate (TAUT): یک تری پپتید که باعث تحریک پوست برای تولید اسید هیالورونیک می&zwnj;شود. این منجر به اثرات بازسازی قابل مشاهده پوست و افزایش استحکام می&zwnj;گردد.</p>
                <p>زایلینوم (چای سیاه): زایلینوم از تخمیر چای سیاه تولید می شود. اثرات محافظتی بر روی ماتریکس خارج سلولی در پوست ایجاد می&zwnj;کند. زایلینوم آسیب گلیکوزیشن به فیبرهای الاستین را کاهش می&zwnj;دهد و باعث نرم&zwnj;تر شدن پوست می&zwnj;شود.</p>
                <p>سدیم هیالورونات: سدیم هیالورونات یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که پوست را عمیقا آبرسانی می&zwnj;کند. خواص رطوبت&zwnj;گیری آن به آن اجازه می&zwnj;دهد تا آب را در سطح پوست جذب و حفظ کند و به عنوان یک مخزن رطوبت عمل کند.</p>
                <p>عصاره گل آرنیکا مونتانا: این عصاره دارای خواص ضد التهابی و تسکین دهنده است. این به کاهش درد و تورم مرتبط با روش&zwnj;های پوستی کمک می&zwnj;کند.</p>
                <p>سدیم PCA: سدیم PCA یک مرطوب&zwnj;کننده و جزء طبیعی عامل مرطوب کننده طبیعی پوست است. آب را جذب و حفظ می&zwnj;کند و به کاهش از دست دادن رطوبت از پوست کمک می&zwnj;کند.</p>
                <h3>LumiLac</h3>
                <p>LumiLac به طور منحصر به فرد جوانسازی پوست را تسریع می&zwnj;کند و بافت را اصلاح می&zwnj;کند و در عین حال رنگ پوست و آبرسانی را بهبود می&zwnj;بخشد. اسید لاکتیک ۱۰ درصد با ShieldSkin به همه انواع پوست اجازه می&zwnj;دهد تا از این کرم AHA در تمام طول سال بدون مشکل استفاده کنند. این کرم بافتی سبک و بدون چربی دارد که به سرعت جذب پوست می&zwnj;شود و برای انواع پوست مناسب است.</p>
                <p>فواید:</p>
                <p>- ظاهر چین و چروک و خطوط ریز را کاهش می&zwnj;دهد.</p>
                <p>- باعث جابجایی سلولی و لایه برداری می&zwnj;شود.</p>
                <p>- آسیب ناشی از نور خورشید را ترمیم می&zwnj;کند و از ایجاد توده جلوگیری می&zwnj;کند.</p>
                <p>- تراکم و خاصیت ارتجاعی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- به کاهش تغییر رنگ و هایپرپیگمانتاسیون کمک می&zwnj;کند.</p>
                <p>- برای استفاده در اطراف ناحیه ظریف چشم بی&zwnj;خطر است.</p>
                <p>- تداخلی با سایر داروهای آرایشی یا پزشکی ندارد.</p>
                <h3>کرم فیلر عمیق</h3>
                <p>این کرم ضد چروک حاوی چندین ترکیب ضد پیری است که اثرات پرکننده چین و چروک را ایجاد می&zwnj;کند. چین و چروک&zwnj;ها را کاهش می&zwnj;دهد و در عین حال پوست را عمیقا آبرسانی می&zwnj;کند و پوست را تقویت می&zwnj;کند تا پوستی یکدست، صاف و الاستیک داشته باشید.</p>
                <h4>عناصر فعال</h4>
                <p>پالمیتویل هگزاپپتید-:۱۴ یک پپتید ضد پیری است که تولید کلاژن، فعالیت فیبروبلاست و خاصیت ارتجاعی را تحریک می&zwnj;کند و در عین حال آنزیم&zwnj;های MMP را مهار می&zwnj;کند. این منجر به کاهش خطوط ریز، چین و چروک و بهبود خاصیت ارتجاعی پوست می&zwnj;شود.</p>
                <p>Palmitoyl Tripeptide-38 (MATRIXYL synthe'6TM): یک پپتید تقلیدی ماتریکینی است که تولید شش مولکول ماتریکس مختلف را در پوست تحریک می کند که به پر کردن چین و چروک ها کمک می کند.</p>
                <p>پالمیتویل ایزولوسین یک اسید آمینه ضروری است که از ترمیم و التیام بافت پشتیبانی می&zwnj;کند. این ماده با تحریک سلول&zwnj;های چربی در پوست، به عنوان یک لیپوفیلر عمل می&zwnj;کند و از پیری آن&zwnj;ها به سلول&zwnj;های MAD ناکارآمد جلوگیری می کند. همچنین تعامل بین فیبروبلاست ها، سلول های تولید کننده کلاژن و ماتریکس پوستی را افزایش می دهد. پالمیتویل ایزولوسین دارای خواص آنتی اکسیدانی نیز هست.</p>
                <p>عصاره تخمیر آلتروموناس: یک ماده بسیار مرطوب کننده است. این عصاره اسید هیالورونیک و تولید چربی را در سلول&zwnj;های پیر پوست بازیابی می&zwnj;کند و از عملکرد پوست پشتیبانی می&zwnj;کند. این آبرسانی و یکپارچگی پوست را بهبود می بخشد.</p>
                <p>عصاره Swertia Chirata: یک ماده ابتکاری است که از برگ های جنتیان هندی به دست می&zwnj;آید. برای کمک به جوانسازی و ضخیم شدن پوست فرموله شده است. مطالعات نشان داده است که می تواند چین و چروک های عمودی اطراف لب را کاهش دهد. این عصاره گیاهی به احیای پوست پیر و بهبود بافت و ظاهر اطراف ناحیه ظریف لب کمک می کند.</p>
                <p>هیالورونات سدیم: یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که آبرسانی عمیقی به پوست می&zwnj;دهد. هیالورونات سدیم به دلیل خاصیت رطوبت&zwnj;گیری یا جذب آب قوی می تواند آب را در سطح پوست نگه دارد و به عنوان یک مخزن رطوبت عمل می&zwnj;کند. این به آن اجازه می&zwnj;دهد تا پوست را برای مدت طولانی&zwnj;تری هیدراته نگه دارد.</p>
                <h3>ضد آفتاب VitaShield</h3>
                <p>ضد آفتاب VitaShield نسل جدید ضد آفتاب ضد پیری با فیلتر UV کپسوله شده و ویتامین&zwnj;های B, C, E است. این ضد آفتاب به طور موثر از پوست در برابر اشعه&zwnj;های UVA/UVB و IR ، اشعه&zwnj;هایی که به روش&zwnj;های مختلف باعث آسیب پوست می&zwnj;شوند، محافظت می&zwnj;کند.</p>
                <p>- در برابر اشعه های UVA/UVB/IR/نور مرئی و رادیکال&zwnj;های آزاد محافظت می&zwnj;کند.</p>
                <p>- مقاومت سلول را در شرایط تنش دمایی افزایش می&zwnj;دهد.</p>
                <p>- آبرسانی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- از آسیب ماکرومولکولی ماتریکس جلوگیری می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>UV-Pearls&reg; کپسوله شده: معروف به &laquo;عینک آفتابی&raquo; برای پوست. در حالی که فیلتر UV از تماس مستقیم آن با سطح پوست جلوگیری می کند، از پوست محافظت می&zwnj;کند. UV-Pearls کپسوله شده در برابر تنش دمایی مقاوم هستند.</p>
                <p>تیتانیوم دی اکسید (نانو): یک ضد آفتاب بسیار موثر است. این ماده از پوست در برابر طیف کامل تابش خورشیدی - UVA، UVB، نور مرئی و اشعه مادون قرمز - محافظت می&zwnj;کند تا حتی برای انواع پوست های حساس نیز دفاع کاملی در برابر آفتاب ایجاد کند. تیتانیوم دی اکسید محافظت قوی در برابر پیری و چین و چروک ایجاد می&zwnj;کند و در عین حال رنگ غیر یکسان پوست را همسان می&zwnj;کند.</p>
                <p>اکتوئین: یک ماده چند منظوره با فواید پوستی متعدد است. اکتوئین می&zwnj;تواند تولید سلول&zwnj;های آفتاب سوختگی و آسیب های ناشی از اشعه UVA را کاهش دهد. اکتوئین همچنین به عنوان یک مخزن رطوبت برای جلوگیری از از دست دادن آب در انواع پوست های خشک و حساس مانند درماتیت آتوپیک عمل می&zwnj;کند. این ماده با حفظ آب بدن، به کاهش علائم خشکی کمک می&zwnj;کند.</p>
                <p>ویتامین&zwnj;های C و E: در نقش آنتی اکسیدان</p>
                <p>نیاسینامید: این ماده که به عنوان ویتامین B3 نیز شناخته می شود، یک ترکیب روشن کننده پوست است که از انتقال ملانوزوم ها از ملانوسیت ها به کراتینوسیت ها جلوگیری می کند. ملانوزوم ها اندامک هایی هستند که حاوی رنگدانه ملانین هستند. نیاسینامید با مهار انتقال آنها به کراتینوسیت ها، رنگدانه های پوست را کاهش می دهد و اثر سفید کنندگی دارد. این جزء سیستم های بیولوژیکی مختلف و یک ماده مغذی ضروری برای بدن است.</p>
                <p><strong>ارتقای دوم: مراقبت </strong><strong>&ndash;</strong><strong> هر سه هفته/یک ماه یک&zwnj;بار</strong></p>
                <p>C-Purity foam</p>
                <p>فوم C-Purity یک فرمول پاک&zwnj;کننده عمیق، چربی&zwnj;های اضافی را از بین می&zwnj;برد و ناخالصی&zwnj;های سطحی را برای پوستی تمیز، نرم و لطیف پاک می&zwnj;کند. محصول حاوی SLS نیست.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>آسکوربیل تترا ایزوپالمیتات: یک مشتق ویتامین C محلول در روغن. به عنوان یک آنتی اکسیدان و عامل سفید کننده قوی، با قابلیت ضد آکنه و ضد پیری عمل می کند.</li>
                    <li>بابونه مقطر: عصاره گل بابونه که از طریق تقطیر تولید می شود. یک هیدروسول تسکین دهنده ملایم است که برای طراوت و آرامش پوست استفاده می شود.</li>
                </ul>
                <p>Pre-peel conditioner</p>
                <p>آماده سازی پوست برای لایه برداری، پرکننده و لیزر درمانی. نرم کننده پیش لایه برداری برای آماده سازی پوست برای هر گونه درمان مانند لایه برداری، فیلر و لیزر استفاده می شود.</p>
                <p>با چربی زدایی پوست، پوست را برای درمان های لایه برداری آماده می کند و به پخش و نفوذ یکنواخت عوامل لایه بردار کمک می&zwnj;کند. نرم کننده پیش لایه برداری حاوی ماده ضد تحریک ShieldSkin است.</p>
                <p>PyPeel 20 نانو&ndash; 5 الی6 دقیقه (به مرور زمان اگر شرایط پوست اجازه دهد، زمان اعمال می&zwnj;تواند بیشتر شود.)</p>
                <p>ژل تیکسوتروپیک نانوساختار</p>
                <p>اسید پیروویک 20%</p>
                <p>به همراه ShieldSkin</p>
                <p>فقط برای استفاده حرفه ای در کلینیک</p>
                <p>pH&lt;۱</p>
                <p>۵۰ گرم / ۱.۷۶ اونس E.</p>
                <p>اسید پیروویک یک آلفا کتو اسید کوچک است که می تواند عمیقاً به پوست نفوذ کند. این یک عامل شیمیایی قوی در نظر گرفته می شود اما به عنوان غیر سمی طبقه بندی می شود. مخصوصا برای پوست های تیره تر و پوست های قومی مناسب است. با این حال، باید با احتیاط مصرف شود زیرا می تواند باعث سوختگی پوست و چشم شود و در صورت جذب از طریق پوست یا بلعیدن ممکن است مضر باشد. استنشاق اسید پیروویک نیز می تواند اثرات شدید سلامتی داشته باشد. رعایت دستورالعمل های ایمنی هنگام استفاده از محصولات حاوی پیروویک اسید بسیار مهم است.</p>
                <p>خنثی&zwnj;کننده لایه بردار</p>
                <p>محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده برای خنثی کردن اسیدهای باقی مانده</p>
                <p>خنثی کننده لایه&zwnj;بردار یک محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده است که برای خنثی کردن اسیدهای باقیمانده که ممکن است پس از استفاده از لایه برداری شیمیایی روی پوست باقی بماند، استفاده می شود. این محصول اجازه می دهد تا عمق لایه برداری را کنترل کنید و اطمینان حاصل کنید که اسید روی پوست پس از اتمام درمان خنثی می&zwnj;شود. خنثی کننده لایه برداری حاوی ShieldSkin ضد تحریک است.</p>
                <p>شستشو</p>
                <h3>کمپلکس پپتیدی لیفت و سفید کننده (توصیه می شود از آمپول کامل با دستگاه الکتروپوریشن استفاده شود) یا 3/1 از آمپول کمپلکس پپتیدی لیفت و سفیدکننده به همراه مخلوط ترموژل (2/1) + CoolRelief (2/1) &ndash; ماساژ به مدت 10 دقیقه و سپس شستشو</h3>
                <p>کمپلکس پپتیدی لیفت و سفید کننده یک ترکیب موثر و بسیار غلیظ از پپتیدها است که برای دستیابی به اثر قابل توجهی در ثبات و سفیدی پوست استفاده می شود.</p>
                <p>- مسیرهای بازسازی متعدد در درم را فعال می کند.</p>
                <p>- عمق چین و چروک را به میزان قابل توجهی کاهش می دهد.</p>
                <p>- سطح رطوبت و تورم پوست را بهبود می بخشد.</p>
                <p>- سطح تغذیه و خاصیت ارتجاعی پوست را افزایش می دهد.</p>
                <p>- اثر سفید کنندگی پوست را فراهم می کند.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>Palmitoyl Tripeptide-38 (Matrixyl Synthe'6TM): این فرمول حاوی غلظت بسیار بالایی از Matrixyl Synthe'6 است و یک تقویت کننده سفت کننده پوست در نظر گرفته می شود. این فرمول یک پپتید تقلیدی ماتریکین است که با سنتز ۶ جزء ساختاری اصلی درم، باعث تسریع روند پر کردن چین و چروک می&zwnj;شود. مواد سازنده را با تحریک سنتز شش مولکول اصلی - اسید هیالورونیک، کلاژن I، III و IV، لامینین ها و فیبرونکتین فراهم می&zwnj;کند و به طور قابل توجهی پوست را سفت می&zwnj;کند و چین و چروک ها را پر می&zwnj;کند.</li>
                    <li>سیکلوتتراپپتید-۲۴ آمینو سیکلوهگزان کربوکسیلات: یک ساختار حلقوی منحصر به فرد که با چین و چروک و خطوط ریز مقابله می&zwnj;کند. طراحی منحصر به فرد آن به عنوان یک پپتید هوشمند و انتخابی، فرآیندهای طبیعی ارتباط و ترمیم پوست را تقلید می کند.</li>
                    <li>اُلیگوپپتید-68: عامل سفید کننده نوآورانه و منحصر به فرد که ظاهر لکه های تیره و تغییر رنگ پوست را ناپیداتر می&zwnj;کند.</li>
                    <li>عصاره Swertia Chirata: یک ماده تشکیل شده از برگ&zwnj;های جنتیان هندی است. این عصاره سلول های بنیادی مشتق شده از چربی را فعال کرده، تولید فاکتورهای رشد کراتینوسیت را تقویت می&zwnj;کند و قادر به تحریک تکثیر کراتینوسیت&zwnj;ها برای بازسازی اپیدرم نازک و بهبود ظاهر پوست چروکیده است. همچنین باعث بهبود ظاهر چین و چروک&zwnj;های عمودی و کاهش چین و چروک&zwnj;های لب می&zwnj;شود.</li>
                    <li>هیالورونات سدیم: اسید هیالورونیک با وزن مولکولی بالا با خلوص بسیار بالا که آبرسانی عمیق را فراهم می کند. خاصیت رطوبت سنجی قوی هیالورونات سدیم باعث حفظ آب در سطح پوست می شود و نقش مخزن آب را ایفا می کند.</li>
                    <li>سدیم PCA: یک جزء طبیعی NMF (عامل مرطوب کننده طبیعی). سدیم PCA بسیار جاذب آب است و می&zwnj;تواند چندین برابر وزن خود را در آب نگه دارد، بنابراین به کاهش از دست دادن رطوبت از طریق تبخیر (از دست دادن آب ترانس اپیدرمی) کمک می&zwnj;کند.</li>
                </ul>
                <p>&nbsp;</p>
                <p>ماسک ProLift &ndash; 15 دقیقه</p>
                <p>ماسک ProLift یک محصول مراقبت از پوست ضد پیری پیشرفته است که بلافاصله پوست را لیفت و سفت می کند. نتایج سریع ضد چین و چروک را ارائه می دهد و در عین حال پوست را آبرسانی، نرم و آرام می کند. تنها ۵ دقیقه پس از استفاده از آن، پوست شما شاداب به نظر می رسد.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>استیل دی پپتید-۱ ستیل استر: یک ماده فعال پپتیدی ملایم و آرامش بخش در لوازم آرایشی است. این اولین ماده در نوع خود است که به پوست سیگنال کاهش تنش می&zwnj;دهد. ترشح اندورفین را تحریک می&zwnj;کند و اثری خوشایند بر روی پوست ایجاد می&zwnj;کند. همچنین از انقباضات ماهیچه ای که باعث بروز چین و چروک و خطوط می شود جلوگیری می&zwnj;کند.</li>
                    <li>پروتئین کنجد هیدرولیز شده PG - پروپیل متیل سیلاندیول: یک ماده صاف کننده نسل جدید است. حاوی یک عامل سفت کننده فوری جدید است که بلافاصله پوست را صاف و لیفت می&zwnj;کند. علاوه بر یک سفت کننده سریع، این ماده پوست را عمیقاً آبرسانی می&zwnj;کند. در یک لحظه خطوط ریز و چین و چروک ها را صاف می&zwnj;کند و در عین حال پوست را کاملا مرطوب می&zwnj;کند.</li>
                    <li>آلانتوئین: یک ماده فعال است که از پوست محافظت می&zwnj;کند و آن را تسکین می&zwnj;دهد و به طور طبیعی روند بهبود پوست را تحریک می کند.</li>
                    <li>شی باتر: حاوی غلظت بالایی از ویتامین&zwnj;ها و اسیدهای چرب طبیعی است که آن را به طور باورنکردنی مقوی و مرطوب کننده برای پوست می کند.</li>
                </ul>
                <p>&nbsp;</p>
                <h4>سرم فیلر عمیق</h4>
                <p>این سرم ضد چین و چروک، تراکم، استحکام و قابلیت ارتجاعی پوست را بهبود می&zwnj;بخشد و در عین حال آبرسانی عمیقی دارد. از ساخت و جوانسازی پوست که از طریق روش&zwnj;هایی مانند لایه برداری، میکرونیدلینگ، RF و لیزر انجام می شود، پشتیبانی می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>MG6P: این سرم حاوی ترکیبات فعالی است که با همکاری یکدیگر باعث افزایش کلاژن و الاستین در پوست می&zwnj;شوند. MG6P به سلول های پوست انرژی می&zwnj;دهد تا تولید این پروتئین&zwnj;های کلیدی را افزایش دهد. اسیدهای آمینه پرولین و لیزین بلوک&zwnj;های سازنده برای ساخت کلاژن و الاستین هستند. مس به تثبیت ساختارهای کلاژن و الاستین کمک می&zwnj;کند. این مواد با هم پوست را تحریک می&zwnj;کنند+ تا فیبرهای کلاژن و الاستین بیشتری را سنتز کند که استحکام، کشش و اثرات ضد پیری را بهبود می&zwnj;بخشد.</p>
                <p>Tetradecyl Aminobutyroylvalylaminobutyric Urea Trifluoroacetate (TAUT): یک تری پپتید که باعث تحریک پوست برای تولید اسید هیالورونیک می&zwnj;شود. این منجر به اثرات بازسازی قابل مشاهده پوست و افزایش استحکام می&zwnj;گردد.</p>
                <p>زایلینوم (چای سیاه): زایلینوم از تخمیر چای سیاه تولید می شود. اثرات محافظتی بر روی ماتریکس خارج سلولی در پوست ایجاد می&zwnj;کند. زایلینوم آسیب گلیکوزیشن به فیبرهای الاستین را کاهش می&zwnj;دهد و باعث نرم&zwnj;تر شدن پوست می&zwnj;شود.</p>
                <p>سدیم هیالورونات: سدیم هیالورونات یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که پوست را عمیقا آبرسانی می&zwnj;کند. خواص رطوبت&zwnj;گیری آن به آن اجازه می&zwnj;دهد تا آب را در سطح پوست جذب و حفظ کند و به عنوان یک مخزن رطوبت عمل کند.</p>
                <p>عصاره گل آرنیکا مونتانا: این عصاره دارای خواص ضد التهابی و تسکین دهنده است. این به کاهش درد و تورم مرتبط با روش&zwnj;های پوستی کمک می&zwnj;کند.</p>
                <p>سدیم PCA: سدیم PCA یک مرطوب&zwnj;کننده و جزء طبیعی عامل مرطوب کننده طبیعی پوست است. آب را جذب و حفظ می&zwnj;کند و به کاهش از دست دادن رطوبت از پوست کمک می&zwnj;کند.</p>
                <h3>LumiLac</h3>
                <p>LumiLac به طور منحصر به فرد جوانسازی پوست را تسریع می&zwnj;کند و بافت را اصلاح می&zwnj;کند و در عین حال رنگ پوست و آبرسانی را بهبود می&zwnj;بخشد. اسید لاکتیک ۱۰ درصد با ShieldSkin به همه انواع پوست اجازه می&zwnj;دهد تا از این کرم AHA در تمام طول سال بدون مشکل استفاده کنند. این کرم بافتی سبک و بدون چربی دارد که به سرعت جذب پوست می&zwnj;شود و برای انواع پوست مناسب است.</p>
                <p>فواید:</p>
                <p>- ظاهر چین و چروک و خطوط ریز را کاهش می&zwnj;دهد.</p>
                <p>- باعث جابجایی سلولی و لایه برداری می&zwnj;شود.</p>
                <p>- آسیب ناشی از نور خورشید را ترمیم می&zwnj;کند و از ایجاد توده جلوگیری می&zwnj;کند.</p>
                <p>- تراکم و خاصیت ارتجاعی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- به کاهش تغییر رنگ و هایپرپیگمانتاسیون کمک می&zwnj;کند.</p>
                <p>- برای استفاده در اطراف ناحیه ظریف چشم بی&zwnj;خطر است.</p>
                <p>- تداخلی با سایر داروهای آرایشی یا پزشکی ندارد.</p>
                <h3>کرم فیلر عمیق</h3>
                <p>این کرم ضد چروک حاوی چندین ترکیب ضد پیری است که اثرات پرکننده چین و چروک را ایجاد می&zwnj;کند. چین و چروک&zwnj;ها را کاهش می&zwnj;دهد و در عین حال پوست را عمیقا آبرسانی می&zwnj;کند و پوست را تقویت می&zwnj;کند تا پوستی یکدست، صاف و الاستیک داشته باشید.</p>
                <h4>عناصر فعال</h4>
                <p>پالمیتویل هگزاپپتید-:۱۴ یک پپتید ضد پیری است که تولید کلاژن، فعالیت فیبروبلاست و خاصیت ارتجاعی را تحریک می&zwnj;کند و در عین حال آنزیم&zwnj;های MMP را مهار می&zwnj;کند. این منجر به کاهش خطوط ریز، چین و چروک و بهبود خاصیت ارتجاعی پوست می&zwnj;شود.</p>
                <p>Palmitoyl Tripeptide-38 (MATRIXYL synthe'6TM): یک پپتید تقلیدی ماتریکینی است که تولید شش مولکول ماتریکس مختلف را در پوست تحریک می کند که به پر کردن چین و چروک ها کمک می کند.</p>
                <p>پالمیتویل ایزولوسین یک اسید آمینه ضروری است که از ترمیم و التیام بافت پشتیبانی می&zwnj;کند. این ماده با تحریک سلول&zwnj;های چربی در پوست، به عنوان یک لیپوفیلر عمل می&zwnj;کند و از پیری آن&zwnj;ها به سلول&zwnj;های MAD ناکارآمد جلوگیری می کند. همچنین تعامل بین فیبروبلاست ها، سلول های تولید کننده کلاژن و ماتریکس پوستی را افزایش می دهد. پالمیتویل ایزولوسین دارای خواص آنتی اکسیدانی نیز هست.</p>
                <p>عصاره تخمیر آلتروموناس: یک ماده بسیار مرطوب کننده است. این عصاره اسید هیالورونیک و تولید چربی را در سلول&zwnj;های پیر پوست بازیابی می&zwnj;کند و از عملکرد پوست پشتیبانی می&zwnj;کند. این آبرسانی و یکپارچگی پوست را بهبود می بخشد.</p>
                <p>عصاره Swertia Chirata: یک ماده ابتکاری است که از برگ های جنتیان هندی به دست می&zwnj;آید. برای کمک به جوانسازی و ضخیم شدن پوست فرموله شده است. مطالعات نشان داده است که می تواند چین و چروک های عمودی اطراف لب را کاهش دهد. این عصاره گیاهی به احیای پوست پیر و بهبود بافت و ظاهر اطراف ناحیه ظریف لب کمک می کند.</p>
                <p>هیالورونات سدیم: یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که آبرسانی عمیقی به پوست می&zwnj;دهد. هیالورونات سدیم به دلیل خاصیت رطوبت&zwnj;گیری یا جذب آب قوی می تواند آب را در سطح پوست نگه دارد و به عنوان یک مخزن رطوبت عمل می&zwnj;کند. این به آن اجازه می&zwnj;دهد تا پوست را برای مدت طولانی&zwnj;تری هیدراته نگه دارد.</p>
                <h3>ضد آفتاب VitaShield</h3>
                <p>ضد آفتاب VitaShield نسل جدید ضد آفتاب ضد پیری با فیلتر UV کپسوله شده و ویتامین&zwnj;های B, C, E است. این ضد آفتاب به طور موثر از پوست در برابر اشعه&zwnj;های UVA/UVB و IR ، اشعه&zwnj;هایی که به روش&zwnj;های مختلف باعث آسیب پوست می&zwnj;شوند، محافظت می&zwnj;کند.</p>
                <p>- در برابر اشعه های UVA/UVB/IR/نور مرئی و رادیکال&zwnj;های آزاد محافظت می&zwnj;کند.</p>
                <p>- مقاومت سلول را در شرایط تنش دمایی افزایش می&zwnj;دهد.</p>
                <p>- آبرسانی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- از آسیب ماکرومولکولی ماتریکس جلوگیری می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>UV-Pearls&reg; کپسوله شده: معروف به &laquo;عینک آفتابی&raquo; برای پوست. در حالی که فیلتر UV از تماس مستقیم آن با سطح پوست جلوگیری می کند، از پوست محافظت می&zwnj;کند. UV-Pearls کپسوله شده در برابر تنش دمایی مقاوم هستند.</p>
                <p>تیتانیوم دی اکسید (نانو): یک ضد آفتاب بسیار موثر است. این ماده از پوست در برابر طیف کامل تابش خورشیدی - UVA، UVB، نور مرئی و اشعه مادون قرمز - محافظت می&zwnj;کند تا حتی برای انواع پوست های حساس نیز دفاع کاملی در برابر آفتاب ایجاد کند. تیتانیوم دی اکسید محافظت قوی در برابر پیری و چین و چروک ایجاد می&zwnj;کند و در عین حال رنگ غیر یکسان پوست را همسان می&zwnj;کند.</p>
                <p>اکتوئین: یک ماده چند منظوره با فواید پوستی متعدد است. اکتوئین می&zwnj;تواند تولید سلول&zwnj;های آفتاب سوختگی و آسیب های ناشی از اشعه UVA را کاهش دهد. اکتوئین همچنین به عنوان یک مخزن رطوبت برای جلوگیری از از دست دادن آب در انواع پوست های خشک و حساس مانند درماتیت آتوپیک عمل می&zwnj;کند. این ماده با حفظ آب بدن، به کاهش علائم خشکی کمک می&zwnj;کند.</p>
                <p>ویتامین&zwnj;های C و E: در نقش آنتی اکسیدان</p>
                <p>نیاسینامید: این ماده که به عنوان ویتامین B3 نیز شناخته می شود، یک ترکیب روشن کننده پوست است که از انتقال ملانوزوم ها از ملانوسیت ها به کراتینوسیت ها جلوگیری می کند. ملانوزوم ها اندامک هایی هستند که حاوی رنگدانه ملانین هستند. نیاسینامید با مهار انتقال آنها به کراتینوسیت ها، رنگدانه های پوست را کاهش می دهد و اثر سفید کنندگی دارد. این جزء سیستم های بیولوژیکی مختلف و یک ماده مغذی ضروری برای بدن است.</p>
                <p><strong>ارتقای سوم: مراقبت </strong><strong>&ndash;</strong><strong> هر سه هفته/یک ماه یک&zwnj;بار</strong></p>
                <p>C-Purity foam</p>
                <p>فوم C-Purity یک فرمول پاک&zwnj;کننده عمیق، چربی&zwnj;های اضافی را از بین می&zwnj;برد و ناخالصی&zwnj;های سطحی را برای پوستی تمیز، نرم و لطیف پاک می&zwnj;کند. محصول حاوی SLS نیست.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>آسکوربیل تترا ایزوپالمیتات: یک مشتق ویتامین C محلول در روغن. به عنوان یک آنتی اکسیدان و عامل سفید کننده قوی، با قابلیت ضد آکنه و ضد پیری عمل می کند.</li>
                    <li>بابونه مقطر: عصاره گل بابونه که از طریق تقطیر تولید می شود. یک هیدروسول تسکین دهنده ملایم است که برای طراوت و آرامش پوست استفاده می شود.</li>
                </ul>
                <p>Pre-peel conditioner</p>
                <p>آماده سازی پوست برای لایه برداری، پرکننده و لیزر درمانی. نرم کننده پیش لایه برداری برای آماده سازی پوست برای هر گونه درمان مانند لایه برداری، فیلر و لیزر استفاده می شود.</p>
                <p>با چربی زدایی پوست، پوست را برای درمان های لایه برداری آماده می کند و به پخش و نفوذ یکنواخت عوامل لایه بردار کمک می&zwnj;کند. نرم کننده پیش لایه برداری حاوی ماده ضد تحریک ShieldSkin است.</p>
                <p>PyPeel 20 نانو&ndash; 5 الی6 دقیقه (به مرور زمان اگر شرایط پوست اجازه دهد، زمان اعمال می&zwnj;تواند بیشتر شود.)</p>
                <p>ژل تیکسوتروپیک نانوساختار</p>
                <p>اسید پیروویک 20%</p>
                <p>به همراه ShieldSkin</p>
                <p>فقط برای استفاده حرفه ای در کلینیک</p>
                <p>pH&lt;۱</p>
                <p>۵۰ گرم / ۱.۷۶ اونس E.</p>
                <p>اسید پیروویک یک آلفا کتو اسید کوچک است که می تواند عمیقاً به پوست نفوذ کند. این یک عامل شیمیایی قوی در نظر گرفته می شود اما به عنوان غیر سمی طبقه بندی می شود. مخصوصا برای پوست های تیره تر و پوست های قومی مناسب است. با این حال، باید با احتیاط مصرف شود زیرا می تواند باعث سوختگی پوست و چشم شود و در صورت جذب از طریق پوست یا بلعیدن ممکن است مضر باشد. استنشاق اسید پیروویک نیز می تواند اثرات شدید سلامتی داشته باشد. رعایت دستورالعمل های ایمنی هنگام استفاده از محصولات حاوی پیروویک اسید بسیار مهم است.</p>
                <p>خنثی&zwnj;کننده لایه بردار</p>
                <p>محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده برای خنثی کردن اسیدهای باقی مانده</p>
                <p>خنثی کننده لایه&zwnj;بردار یک محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده است که برای خنثی کردن اسیدهای باقیمانده که ممکن است پس از استفاده از لایه برداری شیمیایی روی پوست باقی بماند، استفاده می شود. این محصول اجازه می دهد تا عمق لایه برداری را کنترل کنید و اطمینان حاصل کنید که اسید روی پوست پس از اتمام درمان خنثی می&zwnj;شود. خنثی کننده لایه برداری حاوی ShieldSkin ضد تحریک است.</p>
                <p>شستشو</p>
                <h3>کمپلکس پپتیدی لیفت و سفید کننده (توصیه می شود از آمپول کامل با دستگاه الکتروپوریشن استفاده شود) یا 3/1 از آمپول کمپلکس پپتیدی لیفت و سفیدکننده به همراه مخلوط ترموژل (2/1) + CoolRelief (2/1) &ndash; ماساژ به مدت 10 دقیقه و سپس شستشو</h3>
                <p>کمپلکس پپتیدی لیفت و سفید کننده یک ترکیب موثر و بسیار غلیظ از پپتیدها است که برای دستیابی به اثر قابل توجهی در ثبات و سفیدی پوست استفاده می شود.</p>
                <p>- مسیرهای بازسازی متعدد در درم را فعال می کند.</p>
                <p>- عمق چین و چروک را به میزان قابل توجهی کاهش می دهد.</p>
                <p>- سطح رطوبت و تورم پوست را بهبود می بخشد.</p>
                <p>- سطح تغذیه و خاصیت ارتجاعی پوست را افزایش می دهد.</p>
                <p>- اثر سفید کنندگی پوست را فراهم می کند.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>Palmitoyl Tripeptide-38 (Matrixyl Synthe'6TM): این فرمول حاوی غلظت بسیار بالایی از Matrixyl Synthe'6 است و یک تقویت کننده سفت کننده پوست در نظر گرفته می شود. این فرمول یک پپتید تقلیدی ماتریکین است که با سنتز ۶ جزء ساختاری اصلی درم، باعث تسریع روند پر کردن چین و چروک می&zwnj;شود. مواد سازنده را با تحریک سنتز شش مولکول اصلی - اسید هیالورونیک، کلاژن I، III و IV، لامینین ها و فیبرونکتین فراهم می&zwnj;کند و به طور قابل توجهی پوست را سفت می&zwnj;کند و چین و چروک ها را پر می&zwnj;کند.</li>
                    <li>سیکلوتتراپپتید-۲۴ آمینو سیکلوهگزان کربوکسیلات: یک ساختار حلقوی منحصر به فرد که با چین و چروک و خطوط ریز مقابله می&zwnj;کند. طراحی منحصر به فرد آن به عنوان یک پپتید هوشمند و انتخابی، فرآیندهای طبیعی ارتباط و ترمیم پوست را تقلید می کند.</li>
                    <li>اُلیگوپپتید-68: عامل سفید کننده نوآورانه و منحصر به فرد که ظاهر لکه های تیره و تغییر رنگ پوست را ناپیداتر می&zwnj;کند.</li>
                    <li>عصاره Swertia Chirata: یک ماده تشکیل شده از برگ&zwnj;های جنتیان هندی است. این عصاره سلول های بنیادی مشتق شده از چربی را فعال کرده، تولید فاکتورهای رشد کراتینوسیت را تقویت می&zwnj;کند و قادر به تحریک تکثیر کراتینوسیت&zwnj;ها برای بازسازی اپیدرم نازک و بهبود ظاهر پوست چروکیده است. همچنین باعث بهبود ظاهر چین و چروک&zwnj;های عمودی و کاهش چین و چروک&zwnj;های لب می&zwnj;شود.</li>
                    <li>هیالورونات سدیم: اسید هیالورونیک با وزن مولکولی بالا با خلوص بسیار بالا که آبرسانی عمیق را فراهم می کند. خاصیت رطوبت سنجی قوی هیالورونات سدیم باعث حفظ آب در سطح پوست می شود و نقش مخزن آب را ایفا می کند.</li>
                    <li>سدیم PCA: یک جزء طبیعی NMF (عامل مرطوب کننده طبیعی). سدیم PCA بسیار جاذب آب است و می&zwnj;تواند چندین برابر وزن خود را در آب نگه دارد، بنابراین به کاهش از دست دادن رطوبت از طریق تبخیر (از دست دادن آب ترانس اپیدرمی) کمک می&zwnj;کند.</li>
                </ul>
                <p>&nbsp;</p>
                <p>ماسک ProLift &ndash; 15 دقیقه</p>
                <p>ماسک ProLift یک محصول مراقبت از پوست ضد پیری پیشرفته است که بلافاصله پوست را لیفت و سفت می کند. نتایج سریع ضد چین و چروک را ارائه می دهد و در عین حال پوست را آبرسانی، نرم و آرام می کند. تنها ۵ دقیقه پس از استفاده از آن، پوست شما شاداب به نظر می رسد.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>استیل دی پپتید-۱ ستیل استر: یک ماده فعال پپتیدی ملایم و آرامش بخش در لوازم آرایشی است. این اولین ماده در نوع خود است که به پوست سیگنال کاهش تنش می&zwnj;دهد. ترشح اندورفین را تحریک می&zwnj;کند و اثری خوشایند بر روی پوست ایجاد می&zwnj;کند. همچنین از انقباضات ماهیچه ای که باعث بروز چین و چروک و خطوط می شود جلوگیری می&zwnj;کند.</li>
                    <li>پروتئین کنجد هیدرولیز شده PG - پروپیل متیل سیلاندیول: یک ماده صاف کننده نسل جدید است. حاوی یک عامل سفت کننده فوری جدید است که بلافاصله پوست را صاف و لیفت می&zwnj;کند. علاوه بر یک سفت کننده سریع، این ماده پوست را عمیقاً آبرسانی می&zwnj;کند. در یک لحظه خطوط ریز و چین و چروک ها را صاف می&zwnj;کند و در عین حال پوست را کاملا مرطوب می&zwnj;کند.</li>
                    <li>آلانتوئین: یک ماده فعال است که از پوست محافظت می&zwnj;کند و آن را تسکین می&zwnj;دهد و به طور طبیعی روند بهبود پوست را تحریک می کند.</li>
                    <li>شی باتر: حاوی غلظت بالایی از ویتامین&zwnj;ها و اسیدهای چرب طبیعی است که آن را به طور باورنکردنی مقوی و مرطوب کننده برای پوست می کند.</li>
                </ul>
                <p>&nbsp;</p>
                <h4>سرم فیلر عمیق</h4>
                <p>این سرم ضد چین و چروک، تراکم، استحکام و قابلیت ارتجاعی پوست را بهبود می&zwnj;بخشد و در عین حال آبرسانی عمیقی دارد. از ساخت و جوانسازی پوست که از طریق روش&zwnj;هایی مانند لایه برداری، میکرونیدلینگ، RF و لیزر انجام می شود، پشتیبانی می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>MG6P: این سرم حاوی ترکیبات فعالی است که با همکاری یکدیگر باعث افزایش کلاژن و الاستین در پوست می&zwnj;شوند. MG6P به سلول های پوست انرژی می&zwnj;دهد تا تولید این پروتئین&zwnj;های کلیدی را افزایش دهد. اسیدهای آمینه پرولین و لیزین بلوک&zwnj;های سازنده برای ساخت کلاژن و الاستین هستند. مس به تثبیت ساختارهای کلاژن و الاستین کمک می&zwnj;کند. این مواد با هم پوست را تحریک می&zwnj;کنند+ تا فیبرهای کلاژن و الاستین بیشتری را سنتز کند که استحکام، کشش و اثرات ضد پیری را بهبود می&zwnj;بخشد.</p>
                <p>Tetradecyl Aminobutyroylvalylaminobutyric Urea Trifluoroacetate (TAUT): یک تری پپتید که باعث تحریک پوست برای تولید اسید هیالورونیک می&zwnj;شود. این منجر به اثرات بازسازی قابل مشاهده پوست و افزایش استحکام می&zwnj;گردد.</p>
                <p>زایلینوم (چای سیاه): زایلینوم از تخمیر چای سیاه تولید می شود. اثرات محافظتی بر روی ماتریکس خارج سلولی در پوست ایجاد می&zwnj;کند. زایلینوم آسیب گلیکوزیشن به فیبرهای الاستین را کاهش می&zwnj;دهد و باعث نرم&zwnj;تر شدن پوست می&zwnj;شود.</p>
                <p>سدیم هیالورونات: سدیم هیالورونات یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که پوست را عمیقا آبرسانی می&zwnj;کند. خواص رطوبت&zwnj;گیری آن به آن اجازه می&zwnj;دهد تا آب را در سطح پوست جذب و حفظ کند و به عنوان یک مخزن رطوبت عمل کند.</p>
                <p>عصاره گل آرنیکا مونتانا: این عصاره دارای خواص ضد التهابی و تسکین دهنده است. این به کاهش درد و تورم مرتبط با روش&zwnj;های پوستی کمک می&zwnj;کند.</p>
                <p>سدیم PCA: سدیم PCA یک مرطوب&zwnj;کننده و جزء طبیعی عامل مرطوب کننده طبیعی پوست است. آب را جذب و حفظ می&zwnj;کند و به کاهش از دست دادن رطوبت از پوست کمک می&zwnj;کند.</p>
                <h3>LumiLac</h3>
                <p>LumiLac به طور منحصر به فرد جوانسازی پوست را تسریع می&zwnj;کند و بافت را اصلاح می&zwnj;کند و در عین حال رنگ پوست و آبرسانی را بهبود می&zwnj;بخشد. اسید لاکتیک ۱۰ درصد با ShieldSkin به همه انواع پوست اجازه می&zwnj;دهد تا از این کرم AHA در تمام طول سال بدون مشکل استفاده کنند. این کرم بافتی سبک و بدون چربی دارد که به سرعت جذب پوست می&zwnj;شود و برای انواع پوست مناسب است.</p>
                <p>فواید:</p>
                <p>- ظاهر چین و چروک و خطوط ریز را کاهش می&zwnj;دهد.</p>
                <p>- باعث جابجایی سلولی و لایه برداری می&zwnj;شود.</p>
                <p>- آسیب ناشی از نور خورشید را ترمیم می&zwnj;کند و از ایجاد توده جلوگیری می&zwnj;کند.</p>
                <p>- تراکم و خاصیت ارتجاعی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- به کاهش تغییر رنگ و هایپرپیگمانتاسیون کمک می&zwnj;کند.</p>
                <p>- برای استفاده در اطراف ناحیه ظریف چشم بی&zwnj;خطر است.</p>
                <p>- تداخلی با سایر داروهای آرایشی یا پزشکی ندارد.</p>
                <h3>کرم فیلر عمیق</h3>
                <p>این کرم ضد چروک حاوی چندین ترکیب ضد پیری است که اثرات پرکننده چین و چروک را ایجاد می&zwnj;کند. چین و چروک&zwnj;ها را کاهش می&zwnj;دهد و در عین حال پوست را عمیقا آبرسانی می&zwnj;کند و پوست را تقویت می&zwnj;کند تا پوستی یکدست، صاف و الاستیک داشته باشید.</p>
                <h4>عناصر فعال</h4>
                <p>پالمیتویل هگزاپپتید-:۱۴ یک پپتید ضد پیری است که تولید کلاژن، فعالیت فیبروبلاست و خاصیت ارتجاعی را تحریک می&zwnj;کند و در عین حال آنزیم&zwnj;های MMP را مهار می&zwnj;کند. این منجر به کاهش خطوط ریز، چین و چروک و بهبود خاصیت ارتجاعی پوست می&zwnj;شود.</p>
                <p>Palmitoyl Tripeptide-38 (MATRIXYL synthe'6TM): یک پپتید تقلیدی ماتریکینی است که تولید شش مولکول ماتریکس مختلف را در پوست تحریک می کند که به پر کردن چین و چروک ها کمک می کند.</p>
                <p>پالمیتویل ایزولوسین یک اسید آمینه ضروری است که از ترمیم و التیام بافت پشتیبانی می&zwnj;کند. این ماده با تحریک سلول&zwnj;های چربی در پوست، به عنوان یک لیپوفیلر عمل می&zwnj;کند و از پیری آن&zwnj;ها به سلول&zwnj;های MAD ناکارآمد جلوگیری می کند. همچنین تعامل بین فیبروبلاست ها، سلول های تولید کننده کلاژن و ماتریکس پوستی را افزایش می دهد. پالمیتویل ایزولوسین دارای خواص آنتی اکسیدانی نیز هست.</p>
                <p>عصاره تخمیر آلتروموناس: یک ماده بسیار مرطوب کننده است. این عصاره اسید هیالورونیک و تولید چربی را در سلول&zwnj;های پیر پوست بازیابی می&zwnj;کند و از عملکرد پوست پشتیبانی می&zwnj;کند. این آبرسانی و یکپارچگی پوست را بهبود می بخشد.</p>
                <p>عصاره Swertia Chirata: یک ماده ابتکاری است که از برگ های جنتیان هندی به دست می&zwnj;آید. برای کمک به جوانسازی و ضخیم شدن پوست فرموله شده است. مطالعات نشان داده است که می تواند چین و چروک های عمودی اطراف لب را کاهش دهد. این عصاره گیاهی به احیای پوست پیر و بهبود بافت و ظاهر اطراف ناحیه ظریف لب کمک می کند.</p>
                <p>هیالورونات سدیم: یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که آبرسانی عمیقی به پوست می&zwnj;دهد. هیالورونات سدیم به دلیل خاصیت رطوبت&zwnj;گیری یا جذب آب قوی می تواند آب را در سطح پوست نگه دارد و به عنوان یک مخزن رطوبت عمل می&zwnj;کند. این به آن اجازه می&zwnj;دهد تا پوست را برای مدت طولانی&zwnj;تری هیدراته نگه دارد.</p>
                <h3>ضد آفتاب VitaShield</h3>
                <p>ضد آفتاب VitaShield نسل جدید ضد آفتاب ضد پیری با فیلتر UV کپسوله شده و ویتامین&zwnj;های B, C, E است. این ضد آفتاب به طور موثر از پوست در برابر اشعه&zwnj;های UVA/UVB و IR ، اشعه&zwnj;هایی که به روش&zwnj;های مختلف باعث آسیب پوست می&zwnj;شوند، محافظت می&zwnj;کند.</p>
                <p>- در برابر اشعه های UVA/UVB/IR/نور مرئی و رادیکال&zwnj;های آزاد محافظت می&zwnj;کند.</p>
                <p>- مقاومت سلول را در شرایط تنش دمایی افزایش می&zwnj;دهد.</p>
                <p>- آبرسانی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- از آسیب ماکرومولکولی ماتریکس جلوگیری می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>UV-Pearls&reg; کپسوله شده: معروف به &laquo;عینک آفتابی&raquo; برای پوست. در حالی که فیلتر UV از تماس مستقیم آن با سطح پوست جلوگیری می کند، از پوست محافظت می&zwnj;کند. UV-Pearls کپسوله شده در برابر تنش دمایی مقاوم هستند.</p>
                <p>تیتانیوم دی اکسید (نانو): یک ضد آفتاب بسیار موثر است. این ماده از پوست در برابر طیف کامل تابش خورشیدی - UVA، UVB، نور مرئی و اشعه مادون قرمز - محافظت می&zwnj;کند تا حتی برای انواع پوست های حساس نیز دفاع کاملی در برابر آفتاب ایجاد کند. تیتانیوم دی اکسید محافظت قوی در برابر پیری و چین و چروک ایجاد می&zwnj;کند و در عین حال رنگ غیر یکسان پوست را همسان می&zwnj;کند.</p>
                <p>اکتوئین: یک ماده چند منظوره با فواید پوستی متعدد است. اکتوئین می&zwnj;تواند تولید سلول&zwnj;های آفتاب سوختگی و آسیب های ناشی از اشعه UVA را کاهش دهد. اکتوئین همچنین به عنوان یک مخزن رطوبت برای جلوگیری از از دست دادن آب در انواع پوست های خشک و حساس مانند درماتیت آتوپیک عمل می&zwnj;کند. این ماده با حفظ آب بدن، به کاهش علائم خشکی کمک می&zwnj;کند.</p>
                <p>ویتامین&zwnj;های C و E: در نقش آنتی اکسیدان</p>
                <p>نیاسینامید: این ماده که به عنوان ویتامین B3 نیز شناخته می شود، یک ترکیب روشن کننده پوست است که از انتقال ملانوزوم ها از ملانوسیت ها به کراتینوسیت ها جلوگیری می کند. ملانوزوم ها اندامک هایی هستند که حاوی رنگدانه ملانین هستند. نیاسینامید با مهار انتقال آنها به کراتینوسیت ها، رنگدانه های پوست را کاهش می دهد و اثر سفید کنندگی دارد. این جزء سیستم های بیولوژیکی مختلف و یک ماده مغذی ضروری برای بدن است.</p>
                <p><strong>ارتقای چهارم: مراقبت </strong><strong>&ndash;</strong><strong> هر سه هفته/یک ماه یک&zwnj;بار</strong></p>
                <p>C-Purity foam</p>
                <p>فوم C-Purity یک فرمول پاک&zwnj;کننده عمیق، چربی&zwnj;های اضافی را از بین می&zwnj;برد و ناخالصی&zwnj;های سطحی را برای پوستی تمیز، نرم و لطیف پاک می&zwnj;کند. محصول حاوی SLS نیست.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>آسکوربیل تترا ایزوپالمیتات: یک مشتق ویتامین C محلول در روغن. به عنوان یک آنتی اکسیدان و عامل سفید کننده قوی، با قابلیت ضد آکنه و ضد پیری عمل می کند.</li>
                    <li>بابونه مقطر: عصاره گل بابونه که از طریق تقطیر تولید می شود. یک هیدروسول تسکین دهنده ملایم است که برای طراوت و آرامش پوست استفاده می شود.</li>
                </ul>
                <p>Pre-peel conditioner</p>
                <p>آماده سازی پوست برای لایه برداری، پرکننده و لیزر درمانی. نرم کننده پیش لایه برداری برای آماده سازی پوست برای هر گونه درمان مانند لایه برداری، فیلر و لیزر استفاده می شود.</p>
                <p>با چربی زدایی پوست، پوست را برای درمان های لایه برداری آماده می کند و به پخش و نفوذ یکنواخت عوامل لایه بردار کمک می کند. نرم کننده پیش لایه برداری حاوی ماده ضد تحریک ShieldSkin است.</p>
                <p>GlyPeel 50 &ndash; 5 الی6 دقیقه &ndash; شستشو ندهید (به مرور زمان اگر شرایط پوست اجازه دهد، زمان اعمال می&zwnj;تواند بیشتر شود.)</p>
                <p>محلول لایه بردار پوست ۵۰% اسید گلیکولیک با ShieldSkin</p>
                <p>&nbsp;pH&lt;1</p>
                <p>50 ml/ a.69 Fl Oz E.<br /> GlyPeel 50 یک لایه بردار موثر اسید گلیکولیک است که علائم قابل مشاهده پیری و آسیب مانند خطوط ریز، چین و چروک، لکه&zwnj;های ایجاد شده در اثر نور خورشید و رنگدانه&zwnj;های ناهموار را بهبود می&zwnj;بخشد. برای پوست&zwnj;های چرب و مشکل دار، به یکنواخت شدن بافت خشن کمک می&zwnj;کند. این ماده با لایه برداری لایه&zwnj;های خارجی و میانی پوست، جلوگیری از ضخیم شدن لایه خارجی کورنئوسیت و کاهش تجمع سلول&zwnj;های سطحی پوست عمل می&zwnj;کند. همچنین پوست مرده را از بین می&zwnj;برد تا بافت را اصلاح کند و تولید کلاژن جدید، الاستین و گلیکوزامینوگلیکان را تحریک کند تا پوستی صاف تر و محکم تر داشته باشد. فرمول حاوی غلظت بالای اسید گلیکولیک ۵۰% همراه با ShieldSkin ، یک ضد تحریک بالینی اثبات شده، برای کاهش خطر تحریک است.</p>
                <p>خنثی&zwnj;کننده لایه بردار</p>
                <p>محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده برای خنثی کردن اسیدهای باقی مانده</p>
                <p>خنثی کننده لایه&zwnj;بردار یک محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده است که برای خنثی کردن اسیدهای باقیمانده که ممکن است پس از استفاده از لایه برداری شیمیایی روی پوست باقی بماند، استفاده می شود. این محصول اجازه می دهد تا عمق لایه برداری را کنترل کنید و اطمینان حاصل کنید که اسید روی پوست پس از اتمام درمان خنثی می&zwnj;شود. خنثی کننده لایه برداری حاوی ShieldSkin ضد تحریک است.</p>
                <p>شستشو</p>
                <h3>کمپلکس پپتیدی لیفت و سفید کننده (توصیه می شود از آمپول کامل با دستگاه الکتروپوریشن استفاده شود) یا 3/1 از آمپول کمپلکس پپتیدی لیفت و سفیدکننده به همراه مخلوط ترموژل (2/1) + CoolRelief (2/1) &ndash; ماساژ به مدت 10 دقیقه و سپس شستشو</h3>
                <p>کمپلکس پپتیدی لیفت و سفید کننده یک ترکیب موثر و بسیار غلیظ از پپتیدها است که برای دستیابی به اثر قابل توجهی در ثبات و سفیدی پوست استفاده می شود.</p>
                <p>- مسیرهای بازسازی متعدد در درم را فعال می کند.</p>
                <p>- عمق چین و چروک را به میزان قابل توجهی کاهش می دهد.</p>
                <p>- سطح رطوبت و تورم پوست را بهبود می بخشد.</p>
                <p>- سطح تغذیه و خاصیت ارتجاعی پوست را افزایش می دهد.</p>
                <p>- اثر سفید کنندگی پوست را فراهم می کند.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>Palmitoyl Tripeptide-38 (Matrixyl Synthe'6TM): این فرمول حاوی غلظت بسیار بالایی از Matrixyl Synthe'6 است و یک تقویت کننده سفت کننده پوست در نظر گرفته می شود. این فرمول یک پپتید تقلیدی ماتریکین است که با سنتز ۶ جزء ساختاری اصلی درم، باعث تسریع روند پر کردن چین و چروک می&zwnj;شود. مواد سازنده را با تحریک سنتز شش مولکول اصلی - اسید هیالورونیک، کلاژن I، III و IV، لامینین ها و فیبرونکتین فراهم می&zwnj;کند و به طور قابل توجهی پوست را سفت می&zwnj;کند و چین و چروک ها را پر می&zwnj;کند.</li>
                    <li>سیکلوتتراپپتید-۲۴ آمینو سیکلوهگزان کربوکسیلات: یک ساختار حلقوی منحصر به فرد که با چین و چروک و خطوط ریز مقابله می&zwnj;کند. طراحی منحصر به فرد آن به عنوان یک پپتید هوشمند و انتخابی، فرآیندهای طبیعی ارتباط و ترمیم پوست را تقلید می کند.</li>
                    <li>اُلیگوپپتید-68: عامل سفید کننده نوآورانه و منحصر به فرد که ظاهر لکه های تیره و تغییر رنگ پوست را ناپیداتر می&zwnj;کند.</li>
                    <li>عصاره Swertia Chirata: یک ماده تشکیل شده از برگ&zwnj;های جنتیان هندی است. این عصاره سلول های بنیادی مشتق شده از چربی را فعال کرده، تولید فاکتورهای رشد کراتینوسیت را تقویت می&zwnj;کند و قادر به تحریک تکثیر کراتینوسیت&zwnj;ها برای بازسازی اپیدرم نازک و بهبود ظاهر پوست چروکیده است. همچنین باعث بهبود ظاهر چین و چروک&zwnj;های عمودی و کاهش چین و چروک&zwnj;های لب می&zwnj;شود.</li>
                    <li>هیالورونات سدیم: اسید هیالورونیک با وزن مولکولی بالا با خلوص بسیار بالا که آبرسانی عمیق را فراهم می کند. خاصیت رطوبت سنجی قوی هیالورونات سدیم باعث حفظ آب در سطح پوست می شود و نقش مخزن آب را ایفا می کند.</li>
                    <li>سدیم PCA: یک جزء طبیعی NMF (عامل مرطوب کننده طبیعی). سدیم PCA بسیار جاذب آب است و می&zwnj;تواند چندین برابر وزن خود را در آب نگه دارد، بنابراین به کاهش از دست دادن رطوبت از طریق تبخیر (از دست دادن آب ترانس اپیدرمی) کمک می&zwnj;کند.</li>
                </ul>
                <h3>SalPeel 20 (دو لایه &ndash; شستشو ندهید)</h3>
                <p>محلول لایه بردار پوست ۲۰% اسید سالیسیلیک با ShieldSkin</p>
                <p>PH&lt;1</p>
                <p>SalPeel 20 خطوط ریز، چین و چروک و آسیب های ناشی از نور خورشید را کاهش می&zwnj;دهد و دارای اثرات کراتولیتیک، تنظیم کننده سبوم و ضد میکروبی است. این محصول درمان موفقی برای پوست ناصاف به همراه جوش و قرمزی مربوط به روزاسه ارائه می&zwnj;کند. فرمول S-Peel 20 حاوی ۲۰٪ اسید سالیسیلیک و ضد تحریک ShieldSkin است.</p>
                <h3>SunShield - پوست&zwnj;های دارای اختلال و چرب</h3>
                <p>پودر معدنی ضد آفتاب با طیف وسیع فرموله شده برای پاسخگویی به نیازهای پوست&zwnj;های دارای اختلال و چرب که برای استفاده روزمره طراحی شده است، پودر معدنی SunShield SPF 50 محافظت فوری و بهینه را در برابر اشعه های مضر UVA، UVB و IR ارائه می&zwnj;کند. این پودر با خواص ضد التهابی خود، راه حل ایده آلی برای محافظت در برابر آفتاب برای پوست&zwnj;های دارای اختلال و چرب است. همچنین به جلوگیری از آسیب&zwnj;های ناشی از نور خورشید مانند پیری و اختلالات رنگدانه&zwnj;ای کمک می&zwnj;کند.</p>
                <h4>مزایای SunShield:</h4>
                <ul>
                    <li>از اشعه های مضر UVA/UVB/IR و رادیکال های آزاد محافظت می&zwnj;کند.</li>
                    <li>خاصیت ضد التهابی ارائه می&zwnj;دهد.</li>
                    <li>به جلوگیری از آسیب های ناشی از نور خورشید، از جمله پیری و اختلالات رنگدانه&zwnj;ای کمک می&zwnj;کند.</li>
                    <li>بسته بندی با کاربری آسان امکان کنترل مقدار پودر را در حین استفاده فراهم می&zwnj;کند.</li>
                    <li>بسته بندی سازگار با محیط زیست - برس و ظرف پودر قابل تعویض</li>
                    <li>موجود در دو رنگ متفاوت</li>
                    <li>غیر حساس به گرما</li>
                    <li>پوشش مات، پوشش بدون درز، اثر بازتابی</li>
                </ul>
                <p>⚠ پس از این ارتقا، تا 6 الی 8 روز (برای ریکاوری کامل پوست) هیچ محصول دارای اسید یا رتینول را استفاده نکنید. تنها استفاده از آبرسان و ضدآفتاب مجاز است.</p>
                <p><strong>ارتقای پنجم: مراقبت </strong><strong>&ndash;</strong><strong> هر سه هفته/یک ماه یک&zwnj;بار</strong></p>
                <p>C-Purity foam</p>
                <p>فوم C-Purity یک فرمول پاک&zwnj;کننده عمیق، چربی&zwnj;های اضافی را از بین می&zwnj;برد و ناخالصی&zwnj;های سطحی را برای پوستی تمیز، نرم و لطیف پاک می&zwnj;کند. محصول حاوی SLS نیست.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>آسکوربیل تترا ایزوپالمیتات: یک مشتق ویتامین C محلول در روغن. به عنوان یک آنتی اکسیدان و عامل سفید کننده قوی، با قابلیت ضد آکنه و ضد پیری عمل می کند.</li>
                    <li>بابونه مقطر: عصاره گل بابونه که از طریق تقطیر تولید می شود. یک هیدروسول تسکین دهنده ملایم است که برای طراوت و آرامش پوست استفاده می شود.</li>
                </ul>
                <p>Pre-peel conditioner</p>
                <p>آماده سازی پوست برای لایه برداری، پرکننده و لیزر درمانی. نرم کننده پیش لایه برداری برای آماده سازی پوست برای هر گونه درمان مانند لایه برداری، فیلر و لیزر استفاده می شود.</p>
                <p>با چربی زدایی پوست، پوست را برای درمان های لایه برداری آماده می کند و به پخش و نفوذ یکنواخت عوامل لایه بردار کمک می&zwnj;کند. نرم کننده پیش لایه برداری حاوی ماده ضد تحریک ShieldSkin است.</p>
                <p>PyPeel 20 نانو&ndash; 8 الی 10 دقیقه (به مرور زمان اگر شرایط پوست اجازه دهد، زمان اعمال می&zwnj;تواند بیشتر شود.)</p>
                <p>ژل تیکسوتروپیک نانوساختار</p>
                <p>اسید پیروویک 20%</p>
                <p>به همراه ShieldSkin</p>
                <p>فقط برای استفاده حرفه ای در کلینیک</p>
                <p>pH&lt;۱</p>
                <p>۵۰ گرم / ۱.۷۶ اونس E.</p>
                <p>اسید پیروویک یک آلفا کتو اسید کوچک است که می تواند عمیقاً به پوست نفوذ کند. این یک عامل شیمیایی قوی در نظر گرفته می شود اما به عنوان غیر سمی طبقه بندی می شود. مخصوصا برای پوست های تیره تر و پوست های قومی مناسب است. با این حال، باید با احتیاط مصرف شود زیرا می تواند باعث سوختگی پوست و چشم شود و در صورت جذب از طریق پوست یا بلعیدن ممکن است مضر باشد. استنشاق اسید پیروویک نیز می تواند اثرات شدید سلامتی داشته باشد. رعایت دستورالعمل های ایمنی هنگام استفاده از محصولات حاوی پیروویک اسید بسیار مهم است.</p>
                <p>خنثی&zwnj;کننده لایه بردار</p>
                <p>محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده برای خنثی کردن اسیدهای باقی مانده</p>
                <p>خنثی کننده لایه&zwnj;بردار یک محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده است که برای خنثی کردن اسیدهای باقیمانده که ممکن است پس از استفاده از لایه برداری شیمیایی روی پوست باقی بماند، استفاده می شود. این محصول اجازه می دهد تا عمق لایه برداری را کنترل کنید و اطمینان حاصل کنید که اسید روی پوست پس از اتمام درمان خنثی می&zwnj;شود. خنثی کننده لایه برداری حاوی ShieldSkin ضد تحریک است.</p>
                <p>شستشو</p>
                <h3>کمپلکس پپتیدی لیفت و سفید کننده (توصیه می شود از آمپول کامل با دستگاه الکتروپوریشن استفاده شود) یا 3/1 از آمپول کمپلکس پپتیدی لیفت و سفیدکننده به همراه مخلوط ترموژل (2/1) + CoolRelief (2/1) &ndash; ماساژ به مدت 10 دقیقه و سپس شستشو</h3>
                <p>کمپلکس پپتیدی لیفت و سفید کننده یک ترکیب موثر و بسیار غلیظ از پپتیدها است که برای دستیابی به اثر قابل توجهی در ثبات و سفیدی پوست استفاده می شود.</p>
                <p>- مسیرهای بازسازی متعدد در درم را فعال می کند.</p>
                <p>- عمق چین و چروک را به میزان قابل توجهی کاهش می دهد.</p>
                <p>- سطح رطوبت و تورم پوست را بهبود می بخشد.</p>
                <p>- سطح تغذیه و خاصیت ارتجاعی پوست را افزایش می دهد.</p>
                <p>- اثر سفید کنندگی پوست را فراهم می کند.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>Palmitoyl Tripeptide-38 (Matrixyl Synthe'6TM): این فرمول حاوی غلظت بسیار بالایی از Matrixyl Synthe'6 است و یک تقویت کننده سفت کننده پوست در نظر گرفته می شود. این فرمول یک پپتید تقلیدی ماتریکین است که با سنتز ۶ جزء ساختاری اصلی درم، باعث تسریع روند پر کردن چین و چروک می&zwnj;شود. مواد سازنده را با تحریک سنتز شش مولکول اصلی - اسید هیالورونیک، کلاژن I، III و IV، لامینین ها و فیبرونکتین فراهم می&zwnj;کند و به طور قابل توجهی پوست را سفت می&zwnj;کند و چین و چروک ها را پر می&zwnj;کند.</li>
                    <li>سیکلوتتراپپتید-۲۴ آمینو سیکلوهگزان کربوکسیلات: یک ساختار حلقوی منحصر به فرد که با چین و چروک و خطوط ریز مقابله می&zwnj;کند. طراحی منحصر به فرد آن به عنوان یک پپتید هوشمند و انتخابی، فرآیندهای طبیعی ارتباط و ترمیم پوست را تقلید می کند.</li>
                    <li>اُلیگوپپتید-68: عامل سفید کننده نوآورانه و منحصر به فرد که ظاهر لکه های تیره و تغییر رنگ پوست را ناپیداتر می&zwnj;کند.</li>
                    <li>عصاره Swertia Chirata: یک ماده تشکیل شده از برگ&zwnj;های جنتیان هندی است. این عصاره سلول های بنیادی مشتق شده از چربی را فعال کرده، تولید فاکتورهای رشد کراتینوسیت را تقویت می&zwnj;کند و قادر به تحریک تکثیر کراتینوسیت&zwnj;ها برای بازسازی اپیدرم نازک و بهبود ظاهر پوست چروکیده است. همچنین باعث بهبود ظاهر چین و چروک&zwnj;های عمودی و کاهش چین و چروک&zwnj;های لب می&zwnj;شود.</li>
                    <li>هیالورونات سدیم: اسید هیالورونیک با وزن مولکولی بالا با خلوص بسیار بالا که آبرسانی عمیق را فراهم می کند. خاصیت رطوبت سنجی قوی هیالورونات سدیم باعث حفظ آب در سطح پوست می شود و نقش مخزن آب را ایفا می کند.</li>
                    <li>سدیم PCA: یک جزء طبیعی NMF (عامل مرطوب کننده طبیعی). سدیم PCA بسیار جاذب آب است و می&zwnj;تواند چندین برابر وزن خود را در آب نگه دارد، بنابراین به کاهش از دست دادن رطوبت از طریق تبخیر (از دست دادن آب ترانس اپیدرمی) کمک می&zwnj;کند.</li>
                </ul>
                <p>&nbsp;</p>
                <p>ماسک ProLift &ndash; 15 دقیقه</p>
                <p>ماسک ProLift یک محصول مراقبت از پوست ضد پیری پیشرفته است که بلافاصله پوست را لیفت و سفت می کند. نتایج سریع ضد چین و چروک را ارائه می دهد و در عین حال پوست را آبرسانی، نرم و آرام می کند. تنها ۵ دقیقه پس از استفاده از آن، پوست شما شاداب به نظر می رسد.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>استیل دی پپتید-۱ ستیل استر: یک ماده فعال پپتیدی ملایم و آرامش بخش در لوازم آرایشی است. این اولین ماده در نوع خود است که به پوست سیگنال کاهش تنش می&zwnj;دهد. ترشح اندورفین را تحریک می&zwnj;کند و اثری خوشایند بر روی پوست ایجاد می&zwnj;کند. همچنین از انقباضات ماهیچه ای که باعث بروز چین و چروک و خطوط می شود جلوگیری می&zwnj;کند.</li>
                    <li>پروتئین کنجد هیدرولیز شده PG - پروپیل متیل سیلاندیول: یک ماده صاف کننده نسل جدید است. حاوی یک عامل سفت کننده فوری جدید است که بلافاصله پوست را صاف و لیفت می&zwnj;کند. علاوه بر یک سفت کننده سریع، این ماده پوست را عمیقاً آبرسانی می&zwnj;کند. در یک لحظه خطوط ریز و چین و چروک ها را صاف می&zwnj;کند و در عین حال پوست را کاملا مرطوب می&zwnj;کند.</li>
                    <li>آلانتوئین: یک ماده فعال است که از پوست محافظت می&zwnj;کند و آن را تسکین می&zwnj;دهد و به طور طبیعی روند بهبود پوست را تحریک می کند.</li>
                    <li>شی باتر: حاوی غلظت بالایی از ویتامین&zwnj;ها و اسیدهای چرب طبیعی است که آن را به طور باورنکردنی مقوی و مرطوب کننده برای پوست می کند.</li>
                </ul>
                <p>&nbsp;</p>
                <h4>سرم فیلر عمیق</h4>
                <p>این سرم ضد چین و چروک، تراکم، استحکام و قابلیت ارتجاعی پوست را بهبود می&zwnj;بخشد و در عین حال آبرسانی عمیقی دارد. از ساخت و جوانسازی پوست که از طریق روش&zwnj;هایی مانند لایه برداری، میکرونیدلینگ، RF و لیزر انجام می شود، پشتیبانی می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>MG6P: این سرم حاوی ترکیبات فعالی است که با همکاری یکدیگر باعث افزایش کلاژن و الاستین در پوست می&zwnj;شوند. MG6P به سلول های پوست انرژی می&zwnj;دهد تا تولید این پروتئین&zwnj;های کلیدی را افزایش دهد. اسیدهای آمینه پرولین و لیزین بلوک&zwnj;های سازنده برای ساخت کلاژن و الاستین هستند. مس به تثبیت ساختارهای کلاژن و الاستین کمک می&zwnj;کند. این مواد با هم پوست را تحریک می&zwnj;کنند+ تا فیبرهای کلاژن و الاستین بیشتری را سنتز کند که استحکام، کشش و اثرات ضد پیری را بهبود می&zwnj;بخشد.</p>
                <p>Tetradecyl Aminobutyroylvalylaminobutyric Urea Trifluoroacetate (TAUT): یک تری پپتید که باعث تحریک پوست برای تولید اسید هیالورونیک می&zwnj;شود. این منجر به اثرات بازسازی قابل مشاهده پوست و افزایش استحکام می&zwnj;گردد.</p>
                <p>زایلینوم (چای سیاه): زایلینوم از تخمیر چای سیاه تولید می شود. اثرات محافظتی بر روی ماتریکس خارج سلولی در پوست ایجاد می&zwnj;کند. زایلینوم آسیب گلیکوزیشن به فیبرهای الاستین را کاهش می&zwnj;دهد و باعث نرم&zwnj;تر شدن پوست می&zwnj;شود.</p>
                <p>سدیم هیالورونات: سدیم هیالورونات یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که پوست را عمیقا آبرسانی می&zwnj;کند. خواص رطوبت&zwnj;گیری آن به آن اجازه می&zwnj;دهد تا آب را در سطح پوست جذب و حفظ کند و به عنوان یک مخزن رطوبت عمل کند.</p>
                <p>عصاره گل آرنیکا مونتانا: این عصاره دارای خواص ضد التهابی و تسکین دهنده است. این به کاهش درد و تورم مرتبط با روش&zwnj;های پوستی کمک می&zwnj;کند.</p>
                <p>سدیم PCA: سدیم PCA یک مرطوب&zwnj;کننده و جزء طبیعی عامل مرطوب کننده طبیعی پوست است. آب را جذب و حفظ می&zwnj;کند و به کاهش از دست دادن رطوبت از پوست کمک می&zwnj;کند.</p>
                <h3>LumiLac</h3>
                <p>LumiLac به طور منحصر به فرد جوانسازی پوست را تسریع می&zwnj;کند و بافت را اصلاح می&zwnj;کند و در عین حال رنگ پوست و آبرسانی را بهبود می&zwnj;بخشد. اسید لاکتیک ۱۰ درصد با ShieldSkin به همه انواع پوست اجازه می&zwnj;دهد تا از این کرم AHA در تمام طول سال بدون مشکل استفاده کنند. این کرم بافتی سبک و بدون چربی دارد که به سرعت جذب پوست می&zwnj;شود و برای انواع پوست مناسب است.</p>
                <p>فواید:</p>
                <p>- ظاهر چین و چروک و خطوط ریز را کاهش می&zwnj;دهد.</p>
                <p>- باعث جابجایی سلولی و لایه برداری می&zwnj;شود.</p>
                <p>- آسیب ناشی از نور خورشید را ترمیم می&zwnj;کند و از ایجاد توده جلوگیری می&zwnj;کند.</p>
                <p>- تراکم و خاصیت ارتجاعی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- به کاهش تغییر رنگ و هایپرپیگمانتاسیون کمک می&zwnj;کند.</p>
                <p>- برای استفاده در اطراف ناحیه ظریف چشم بی&zwnj;خطر است.</p>
                <p>- تداخلی با سایر داروهای آرایشی یا پزشکی ندارد.</p>
                <h3>کرم فیلر عمیق</h3>
                <p>این کرم ضد چروک حاوی چندین ترکیب ضد پیری است که اثرات پرکننده چین و چروک را ایجاد می&zwnj;کند. چین و چروک&zwnj;ها را کاهش می&zwnj;دهد و در عین حال پوست را عمیقا آبرسانی می&zwnj;کند و پوست را تقویت می&zwnj;کند تا پوستی یکدست، صاف و الاستیک داشته باشید.</p>
                <h4>عناصر فعال</h4>
                <p>پالمیتویل هگزاپپتید-:۱۴ یک پپتید ضد پیری است که تولید کلاژن، فعالیت فیبروبلاست و خاصیت ارتجاعی را تحریک می&zwnj;کند و در عین حال آنزیم&zwnj;های MMP را مهار می&zwnj;کند. این منجر به کاهش خطوط ریز، چین و چروک و بهبود خاصیت ارتجاعی پوست می&zwnj;شود.</p>
                <p>Palmitoyl Tripeptide-38 (MATRIXYL synthe'6TM): یک پپتید تقلیدی ماتریکینی است که تولید شش مولکول ماتریکس مختلف را در پوست تحریک می کند که به پر کردن چین و چروک ها کمک می کند.</p>
                <p>پالمیتویل ایزولوسین یک اسید آمینه ضروری است که از ترمیم و التیام بافت پشتیبانی می&zwnj;کند. این ماده با تحریک سلول&zwnj;های چربی در پوست، به عنوان یک لیپوفیلر عمل می&zwnj;کند و از پیری آن&zwnj;ها به سلول&zwnj;های MAD ناکارآمد جلوگیری می کند. همچنین تعامل بین فیبروبلاست ها، سلول های تولید کننده کلاژن و ماتریکس پوستی را افزایش می دهد. پالمیتویل ایزولوسین دارای خواص آنتی اکسیدانی نیز هست.</p>
                <p>عصاره تخمیر آلتروموناس: یک ماده بسیار مرطوب کننده است. این عصاره اسید هیالورونیک و تولید چربی را در سلول&zwnj;های پیر پوست بازیابی می&zwnj;کند و از عملکرد پوست پشتیبانی می&zwnj;کند. این آبرسانی و یکپارچگی پوست را بهبود می بخشد.</p>
                <p>عصاره Swertia Chirata: یک ماده ابتکاری است که از برگ های جنتیان هندی به دست می&zwnj;آید. برای کمک به جوانسازی و ضخیم شدن پوست فرموله شده است. مطالعات نشان داده است که می تواند چین و چروک های عمودی اطراف لب را کاهش دهد. این عصاره گیاهی به احیای پوست پیر و بهبود بافت و ظاهر اطراف ناحیه ظریف لب کمک می کند.</p>
                <p>هیالورونات سدیم: یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که آبرسانی عمیقی به پوست می&zwnj;دهد. هیالورونات سدیم به دلیل خاصیت رطوبت&zwnj;گیری یا جذب آب قوی می تواند آب را در سطح پوست نگه دارد و به عنوان یک مخزن رطوبت عمل می&zwnj;کند. این به آن اجازه می&zwnj;دهد تا پوست را برای مدت طولانی&zwnj;تری هیدراته نگه دارد.</p>
                <h3>ضد آفتاب VitaShield</h3>
                <p>ضد آفتاب VitaShield نسل جدید ضد آفتاب ضد پیری با فیلتر UV کپسوله شده و ویتامین&zwnj;های B, C, E است. این ضد آفتاب به طور موثر از پوست در برابر اشعه&zwnj;های UVA/UVB و IR ، اشعه&zwnj;هایی که به روش&zwnj;های مختلف باعث آسیب پوست می&zwnj;شوند، محافظت می&zwnj;کند.</p>
                <p>- در برابر اشعه های UVA/UVB/IR/نور مرئی و رادیکال&zwnj;های آزاد محافظت می&zwnj;کند.</p>
                <p>- مقاومت سلول را در شرایط تنش دمایی افزایش می&zwnj;دهد.</p>
                <p>- آبرسانی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- از آسیب ماکرومولکولی ماتریکس جلوگیری می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>UV-Pearls&reg; کپسوله شده: معروف به &laquo;عینک آفتابی&raquo; برای پوست. در حالی که فیلتر UV از تماس مستقیم آن با سطح پوست جلوگیری می کند، از پوست محافظت می&zwnj;کند. UV-Pearls کپسوله شده در برابر تنش دمایی مقاوم هستند.</p>
                <p>تیتانیوم دی اکسید (نانو): یک ضد آفتاب بسیار موثر است. این ماده از پوست در برابر طیف کامل تابش خورشیدی - UVA، UVB، نور مرئی و اشعه مادون قرمز - محافظت می&zwnj;کند تا حتی برای انواع پوست های حساس نیز دفاع کاملی در برابر آفتاب ایجاد کند. تیتانیوم دی اکسید محافظت قوی در برابر پیری و چین و چروک ایجاد می&zwnj;کند و در عین حال رنگ غیر یکسان پوست را همسان می&zwnj;کند.</p>
                <p>اکتوئین: یک ماده چند منظوره با فواید پوستی متعدد است. اکتوئین می&zwnj;تواند تولید سلول&zwnj;های آفتاب سوختگی و آسیب های ناشی از اشعه UVA را کاهش دهد. اکتوئین همچنین به عنوان یک مخزن رطوبت برای جلوگیری از از دست دادن آب در انواع پوست های خشک و حساس مانند درماتیت آتوپیک عمل می&zwnj;کند. این ماده با حفظ آب بدن، به کاهش علائم خشکی کمک می&zwnj;کند.</p>
                <p>ویتامین&zwnj;های C و E: در نقش آنتی اکسیدان</p>
                <p>نیاسینامید: این ماده که به عنوان ویتامین B3 نیز شناخته می شود، یک ترکیب روشن کننده پوست است که از انتقال ملانوزوم ها از ملانوسیت ها به کراتینوسیت ها جلوگیری می کند. ملانوزوم ها اندامک هایی هستند که حاوی رنگدانه ملانین هستند. نیاسینامید با مهار انتقال آنها به کراتینوسیت ها، رنگدانه های پوست را کاهش می دهد و اثر سفید کنندگی دارد. این جزء سیستم های بیولوژیکی مختلف و یک ماده مغذی ضروری برای بدن است.</p>
                <p><strong>ارتقای ششم: مراقبت </strong><strong>&ndash;</strong><strong> هر سه هفته/یک ماه یک&zwnj;بار</strong></p>
                <p>C-Purity foam</p>
                <p>فوم C-Purity یک فرمول پاک&zwnj;کننده عمیق، چربی&zwnj;های اضافی را از بین می&zwnj;برد و ناخالصی&zwnj;های سطحی را برای پوستی تمیز، نرم و لطیف پاک می&zwnj;کند. محصول حاوی SLS نیست.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>آسکوربیل تترا ایزوپالمیتات: یک مشتق ویتامین C محلول در روغن. به عنوان یک آنتی اکسیدان و عامل سفید کننده قوی، با قابلیت ضد آکنه و ضد پیری عمل می کند.</li>
                    <li>بابونه مقطر: عصاره گل بابونه که از طریق تقطیر تولید می شود. یک هیدروسول تسکین دهنده ملایم است که برای طراوت و آرامش پوست استفاده می شود.</li>
                </ul>
                <p>Pre-peel conditioner</p>
                <p>آماده سازی پوست برای لایه برداری، پرکننده و لیزر درمانی. نرم کننده پیش لایه برداری برای آماده سازی پوست برای هر گونه درمان مانند لایه برداری، فیلر و لیزر استفاده می شود.</p>
                <p>با چربی زدایی پوست، پوست را برای درمان های لایه برداری آماده می کند و به پخش و نفوذ یکنواخت عوامل لایه بردار کمک می&zwnj;کند. نرم کننده پیش لایه برداری حاوی ماده ضد تحریک ShieldSkin است.</p>
                <p>PyPeel 40 نانو&ndash; 4 الی 6 دقیقه (به مرور زمان اگر شرایط پوست اجازه دهد، زمان اعمال می&zwnj;تواند بیشتر شود.)</p>
                <p>ژل تیکسوتروپیک نانوساختار</p>
                <p>اسید پیروویک 40%</p>
                <p>به همراه ShieldSkin</p>
                <p>فقط برای استفاده حرفه ای در کلینیک</p>
                <p>pH&lt;۱</p>
                <p>۵۰ گرم / ۱.۷۶ اونس E.</p>
                <p>اسید پیروویک یک آلفا کتو اسید کوچک است که می تواند عمیقاً به پوست نفوذ کند. این یک عامل شیمیایی قوی در نظر گرفته می شود اما به عنوان غیر سمی طبقه بندی می شود. مخصوصا برای پوست های تیره تر و پوست های قومی مناسب است. با این حال، باید با احتیاط مصرف شود زیرا می تواند باعث سوختگی پوست و چشم شود و در صورت جذب از طریق پوست یا بلعیدن ممکن است مضر باشد. استنشاق اسید پیروویک نیز می تواند اثرات شدید سلامتی داشته باشد. رعایت دستورالعمل های ایمنی هنگام استفاده از محصولات حاوی پیروویک اسید بسیار مهم است.</p>
                <p>خنثی&zwnj;کننده لایه بردار</p>
                <p>محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده برای خنثی کردن اسیدهای باقی مانده</p>
                <p>خنثی کننده لایه&zwnj;بردار یک محلول واکنش سریع، طراوت&zwnj;بخش و تسکین&zwnj;دهنده است که برای خنثی کردن اسیدهای باقیمانده که ممکن است پس از استفاده از لایه برداری شیمیایی روی پوست باقی بماند، استفاده می شود. این محصول اجازه می دهد تا عمق لایه برداری را کنترل کنید و اطمینان حاصل کنید که اسید روی پوست پس از اتمام درمان خنثی می&zwnj;شود. خنثی کننده لایه برداری حاوی ShieldSkin ضد تحریک است.</p>
                <p>شستشو</p>
                <h3>کمپلکس پپتیدی لیفت و سفید کننده (توصیه می شود از آمپول کامل با دستگاه الکتروپوریشن استفاده شود) یا 3/1 از آمپول کمپلکس پپتیدی لیفت و سفیدکننده به همراه مخلوط ترموژل (2/1) + CoolRelief (2/1) &ndash; ماساژ به مدت 10 دقیقه و سپس شستشو</h3>
                <p>کمپلکس پپتیدی لیفت و سفید کننده یک ترکیب موثر و بسیار غلیظ از پپتیدها است که برای دستیابی به اثر قابل توجهی در ثبات و سفیدی پوست استفاده می شود.</p>
                <p>- مسیرهای بازسازی متعدد در درم را فعال می کند.</p>
                <p>- عمق چین و چروک را به میزان قابل توجهی کاهش می دهد.</p>
                <p>- سطح رطوبت و تورم پوست را بهبود می بخشد.</p>
                <p>- سطح تغذیه و خاصیت ارتجاعی پوست را افزایش می دهد.</p>
                <p>- اثر سفید کنندگی پوست را فراهم می کند.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>Palmitoyl Tripeptide-38 (Matrixyl Synthe'6TM): این فرمول حاوی غلظت بسیار بالایی از Matrixyl Synthe'6 است و یک تقویت کننده سفت کننده پوست در نظر گرفته می شود. این فرمول یک پپتید تقلیدی ماتریکین است که با سنتز ۶ جزء ساختاری اصلی درم، باعث تسریع روند پر کردن چین و چروک می&zwnj;شود. مواد سازنده را با تحریک سنتز شش مولکول اصلی - اسید هیالورونیک، کلاژن I، III و IV، لامینین ها و فیبرونکتین فراهم می&zwnj;کند و به طور قابل توجهی پوست را سفت می&zwnj;کند و چین و چروک ها را پر می&zwnj;کند.</li>
                    <li>سیکلوتتراپپتید-۲۴ آمینو سیکلوهگزان کربوکسیلات: یک ساختار حلقوی منحصر به فرد که با چین و چروک و خطوط ریز مقابله می&zwnj;کند. طراحی منحصر به فرد آن به عنوان یک پپتید هوشمند و انتخابی، فرآیندهای طبیعی ارتباط و ترمیم پوست را تقلید می کند.</li>
                    <li>اُلیگوپپتید-68: عامل سفید کننده نوآورانه و منحصر به فرد که ظاهر لکه های تیره و تغییر رنگ پوست را ناپیداتر می&zwnj;کند.</li>
                    <li>عصاره Swertia Chirata: یک ماده تشکیل شده از برگ&zwnj;های جنتیان هندی است. این عصاره سلول های بنیادی مشتق شده از چربی را فعال کرده، تولید فاکتورهای رشد کراتینوسیت را تقویت می&zwnj;کند و قادر به تحریک تکثیر کراتینوسیت&zwnj;ها برای بازسازی اپیدرم نازک و بهبود ظاهر پوست چروکیده است. همچنین باعث بهبود ظاهر چین و چروک&zwnj;های عمودی و کاهش چین و چروک&zwnj;های لب می&zwnj;شود.</li>
                    <li>هیالورونات سدیم: اسید هیالورونیک با وزن مولکولی بالا با خلوص بسیار بالا که آبرسانی عمیق را فراهم می کند. خاصیت رطوبت سنجی قوی هیالورونات سدیم باعث حفظ آب در سطح پوست می شود و نقش مخزن آب را ایفا می کند.</li>
                    <li>سدیم PCA: یک جزء طبیعی NMF (عامل مرطوب کننده طبیعی). سدیم PCA بسیار جاذب آب است و می&zwnj;تواند چندین برابر وزن خود را در آب نگه دارد، بنابراین به کاهش از دست دادن رطوبت از طریق تبخیر (از دست دادن آب ترانس اپیدرمی) کمک می&zwnj;کند.</li>
                </ul>
                <p>&nbsp;</p>
                <p>ماسک ProLift &ndash; 15 دقیقه</p>
                <p>ماسک ProLift یک محصول مراقبت از پوست ضد پیری پیشرفته است که بلافاصله پوست را لیفت و سفت می کند. نتایج سریع ضد چین و چروک را ارائه می دهد و در عین حال پوست را آبرسانی، نرم و آرام می کند. تنها ۵ دقیقه پس از استفاده از آن، پوست شما شاداب به نظر می رسد.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>استیل دی پپتید-۱ ستیل استر: یک ماده فعال پپتیدی ملایم و آرامش بخش در لوازم آرایشی است. این اولین ماده در نوع خود است که به پوست سیگنال کاهش تنش می&zwnj;دهد. ترشح اندورفین را تحریک می&zwnj;کند و اثری خوشایند بر روی پوست ایجاد می&zwnj;کند. همچنین از انقباضات ماهیچه ای که باعث بروز چین و چروک و خطوط می شود جلوگیری می&zwnj;کند.</li>
                    <li>پروتئین کنجد هیدرولیز شده PG - پروپیل متیل سیلاندیول: یک ماده صاف کننده نسل جدید است. حاوی یک عامل سفت کننده فوری جدید است که بلافاصله پوست را صاف و لیفت می&zwnj;کند. علاوه بر یک سفت کننده سریع، این ماده پوست را عمیقاً آبرسانی می&zwnj;کند. در یک لحظه خطوط ریز و چین و چروک ها را صاف می&zwnj;کند و در عین حال پوست را کاملا مرطوب می&zwnj;کند.</li>
                    <li>آلانتوئین: یک ماده فعال است که از پوست محافظت می&zwnj;کند و آن را تسکین می&zwnj;دهد و به طور طبیعی روند بهبود پوست را تحریک می کند.</li>
                    <li>شی باتر: حاوی غلظت بالایی از ویتامین&zwnj;ها و اسیدهای چرب طبیعی است که آن را به طور باورنکردنی مقوی و مرطوب کننده برای پوست می کند.</li>
                </ul>
                <p>&nbsp;</p>
                <h4>سرم فیلر عمیق</h4>
                <p>این سرم ضد چین و چروک، تراکم، استحکام و قابلیت ارتجاعی پوست را بهبود می&zwnj;بخشد و در عین حال آبرسانی عمیقی دارد. از ساخت و جوانسازی پوست که از طریق روش&zwnj;هایی مانند لایه برداری، میکرونیدلینگ، RF و لیزر انجام می شود، پشتیبانی می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>MG6P: این سرم حاوی ترکیبات فعالی است که با همکاری یکدیگر باعث افزایش کلاژن و الاستین در پوست می&zwnj;شوند. MG6P به سلول های پوست انرژی می&zwnj;دهد تا تولید این پروتئین&zwnj;های کلیدی را افزایش دهد. اسیدهای آمینه پرولین و لیزین بلوک&zwnj;های سازنده برای ساخت کلاژن و الاستین هستند. مس به تثبیت ساختارهای کلاژن و الاستین کمک می&zwnj;کند. این مواد با هم پوست را تحریک می&zwnj;کنند+ تا فیبرهای کلاژن و الاستین بیشتری را سنتز کند که استحکام، کشش و اثرات ضد پیری را بهبود می&zwnj;بخشد.</p>
                <p>Tetradecyl Aminobutyroylvalylaminobutyric Urea Trifluoroacetate (TAUT): یک تری پپتید که باعث تحریک پوست برای تولید اسید هیالورونیک می&zwnj;شود. این منجر به اثرات بازسازی قابل مشاهده پوست و افزایش استحکام می&zwnj;گردد.</p>
                <p>زایلینوم (چای سیاه): زایلینوم از تخمیر چای سیاه تولید می شود. اثرات محافظتی بر روی ماتریکس خارج سلولی در پوست ایجاد می&zwnj;کند. زایلینوم آسیب گلیکوزیشن به فیبرهای الاستین را کاهش می&zwnj;دهد و باعث نرم&zwnj;تر شدن پوست می&zwnj;شود.</p>
                <p>سدیم هیالورونات: سدیم هیالورونات یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که پوست را عمیقا آبرسانی می&zwnj;کند. خواص رطوبت&zwnj;گیری آن به آن اجازه می&zwnj;دهد تا آب را در سطح پوست جذب و حفظ کند و به عنوان یک مخزن رطوبت عمل کند.</p>
                <p>عصاره گل آرنیکا مونتانا: این عصاره دارای خواص ضد التهابی و تسکین دهنده است. این به کاهش درد و تورم مرتبط با روش&zwnj;های پوستی کمک می&zwnj;کند.</p>
                <p>سدیم PCA: سدیم PCA یک مرطوب&zwnj;کننده و جزء طبیعی عامل مرطوب کننده طبیعی پوست است. آب را جذب و حفظ می&zwnj;کند و به کاهش از دست دادن رطوبت از پوست کمک می&zwnj;کند.</p>
                <h3>LumiLac</h3>
                <p>LumiLac به طور منحصر به فرد جوانسازی پوست را تسریع می&zwnj;کند و بافت را اصلاح می&zwnj;کند و در عین حال رنگ پوست و آبرسانی را بهبود می&zwnj;بخشد. اسید لاکتیک ۱۰ درصد با ShieldSkin به همه انواع پوست اجازه می&zwnj;دهد تا از این کرم AHA در تمام طول سال بدون مشکل استفاده کنند. این کرم بافتی سبک و بدون چربی دارد که به سرعت جذب پوست می&zwnj;شود و برای انواع پوست مناسب است.</p>
                <p>فواید:</p>
                <p>- ظاهر چین و چروک و خطوط ریز را کاهش می&zwnj;دهد.</p>
                <p>- باعث جابجایی سلولی و لایه برداری می&zwnj;شود.</p>
                <p>- آسیب ناشی از نور خورشید را ترمیم می&zwnj;کند و از ایجاد توده جلوگیری می&zwnj;کند.</p>
                <p>- تراکم و خاصیت ارتجاعی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- به کاهش تغییر رنگ و هایپرپیگمانتاسیون کمک می&zwnj;کند.</p>
                <p>- برای استفاده در اطراف ناحیه ظریف چشم بی&zwnj;خطر است.</p>
                <p>- تداخلی با سایر داروهای آرایشی یا پزشکی ندارد.</p>
                <h3>کرم فیلر عمیق</h3>
                <p>این کرم ضد چروک حاوی چندین ترکیب ضد پیری است که اثرات پرکننده چین و چروک را ایجاد می&zwnj;کند. چین و چروک&zwnj;ها را کاهش می&zwnj;دهد و در عین حال پوست را عمیقا آبرسانی می&zwnj;کند و پوست را تقویت می&zwnj;کند تا پوستی یکدست، صاف و الاستیک داشته باشید.</p>
                <h4>عناصر فعال</h4>
                <p>پالمیتویل هگزاپپتید-:۱۴ یک پپتید ضد پیری است که تولید کلاژن، فعالیت فیبروبلاست و خاصیت ارتجاعی را تحریک می&zwnj;کند و در عین حال آنزیم&zwnj;های MMP را مهار می&zwnj;کند. این منجر به کاهش خطوط ریز، چین و چروک و بهبود خاصیت ارتجاعی پوست می&zwnj;شود.</p>
                <p>Palmitoyl Tripeptide-38 (MATRIXYL synthe'6TM): یک پپتید تقلیدی ماتریکینی است که تولید شش مولکول ماتریکس مختلف را در پوست تحریک می کند که به پر کردن چین و چروک ها کمک می کند.</p>
                <p>پالمیتویل ایزولوسین یک اسید آمینه ضروری است که از ترمیم و التیام بافت پشتیبانی می&zwnj;کند. این ماده با تحریک سلول&zwnj;های چربی در پوست، به عنوان یک لیپوفیلر عمل می&zwnj;کند و از پیری آن&zwnj;ها به سلول&zwnj;های MAD ناکارآمد جلوگیری می کند. همچنین تعامل بین فیبروبلاست ها، سلول های تولید کننده کلاژن و ماتریکس پوستی را افزایش می دهد. پالمیتویل ایزولوسین دارای خواص آنتی اکسیدانی نیز هست.</p>
                <p>عصاره تخمیر آلتروموناس: یک ماده بسیار مرطوب کننده است. این عصاره اسید هیالورونیک و تولید چربی را در سلول&zwnj;های پیر پوست بازیابی می&zwnj;کند و از عملکرد پوست پشتیبانی می&zwnj;کند. این آبرسانی و یکپارچگی پوست را بهبود می بخشد.</p>
                <p>عصاره Swertia Chirata: یک ماده ابتکاری است که از برگ های جنتیان هندی به دست می&zwnj;آید. برای کمک به جوانسازی و ضخیم شدن پوست فرموله شده است. مطالعات نشان داده است که می تواند چین و چروک های عمودی اطراف لب را کاهش دهد. این عصاره گیاهی به احیای پوست پیر و بهبود بافت و ظاهر اطراف ناحیه ظریف لب کمک می کند.</p>
                <p>هیالورونات سدیم: یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که آبرسانی عمیقی به پوست می&zwnj;دهد. هیالورونات سدیم به دلیل خاصیت رطوبت&zwnj;گیری یا جذب آب قوی می تواند آب را در سطح پوست نگه دارد و به عنوان یک مخزن رطوبت عمل می&zwnj;کند. این به آن اجازه می&zwnj;دهد تا پوست را برای مدت طولانی&zwnj;تری هیدراته نگه دارد.</p>
                <h3>ضد آفتاب VitaShield</h3>
                <p>ضد آفتاب VitaShield نسل جدید ضد آفتاب ضد پیری با فیلتر UV کپسوله شده و ویتامین&zwnj;های B, C, E است. این ضد آفتاب به طور موثر از پوست در برابر اشعه&zwnj;های UVA/UVB و IR ، اشعه&zwnj;هایی که به روش&zwnj;های مختلف باعث آسیب پوست می&zwnj;شوند، محافظت می&zwnj;کند.</p>
                <p>- در برابر اشعه های UVA/UVB/IR/نور مرئی و رادیکال&zwnj;های آزاد محافظت می&zwnj;کند.</p>
                <p>- مقاومت سلول را در شرایط تنش دمایی افزایش می&zwnj;دهد.</p>
                <p>- آبرسانی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- از آسیب ماکرومولکولی ماتریکس جلوگیری می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>UV-Pearls&reg; کپسوله شده: معروف به &laquo;عینک آفتابی&raquo; برای پوست. در حالی که فیلتر UV از تماس مستقیم آن با سطح پوست جلوگیری می کند، از پوست محافظت می&zwnj;کند. UV-Pearls کپسوله شده در برابر تنش دمایی مقاوم هستند.</p>
                <p>تیتانیوم دی اکسید (نانو): یک ضد آفتاب بسیار موثر است. این ماده از پوست در برابر طیف کامل تابش خورشیدی - UVA، UVB، نور مرئی و اشعه مادون قرمز - محافظت می&zwnj;کند تا حتی برای انواع پوست های حساس نیز دفاع کاملی در برابر آفتاب ایجاد کند. تیتانیوم دی اکسید محافظت قوی در برابر پیری و چین و چروک ایجاد می&zwnj;کند و در عین حال رنگ غیر یکسان پوست را همسان می&zwnj;کند.</p>
                <p>اکتوئین: یک ماده چند منظوره با فواید پوستی متعدد است. اکتوئین می&zwnj;تواند تولید سلول&zwnj;های آفتاب سوختگی و آسیب های ناشی از اشعه UVA را کاهش دهد. اکتوئین همچنین به عنوان یک مخزن رطوبت برای جلوگیری از از دست دادن آب در انواع پوست های خشک و حساس مانند درماتیت آتوپیک عمل می&zwnj;کند. این ماده با حفظ آب بدن، به کاهش علائم خشکی کمک می&zwnj;کند.</p>
                <p>ویتامین&zwnj;های C و E: در نقش آنتی اکسیدان</p>
                <p>نیاسینامید: این ماده که به عنوان ویتامین B3 نیز شناخته می شود، یک ترکیب روشن کننده پوست است که از انتقال ملانوزوم ها از ملانوسیت ها به کراتینوسیت ها جلوگیری می کند. ملانوزوم ها اندامک هایی هستند که حاوی رنگدانه ملانین هستند. نیاسینامید با مهار انتقال آنها به کراتینوسیت ها، رنگدانه های پوست را کاهش می دهد و اثر سفید کنندگی دارد. این جزء سیستم های بیولوژیکی مختلف و یک ماده مغذی ضروری برای بدن است.</p>
                <p><strong>ارتقای هفتم: مراقبت </strong><strong>&ndash;</strong><strong> هر سه هفته/یک ماه یک&zwnj;بار</strong></p>
                <p>C-Purity foam</p>
                <p>فوم C-Purity یک فرمول پاک&zwnj;کننده عمیق، چربی&zwnj;های اضافی را از بین می&zwnj;برد و ناخالصی&zwnj;های سطحی را برای پوستی تمیز، نرم و لطیف پاک می&zwnj;کند. محصول حاوی SLS نیست.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>آسکوربیل تترا ایزوپالمیتات: یک مشتق ویتامین C محلول در روغن. به عنوان یک آنتی اکسیدان و عامل سفید کننده قوی، با قابلیت ضد آکنه و ضد پیری عمل می کند.</li>
                    <li>بابونه مقطر: عصاره گل بابونه که از طریق تقطیر تولید می شود. یک هیدروسول تسکین دهنده ملایم است که برای طراوت و آرامش پوست استفاده می شود.</li>
                </ul>
                <h3>کمپلکس پپتیدی لیفت و سفید کننده (توصیه می شود از آمپول کامل با دستگاه الکتروپوریشن استفاده شود) یا 3/1 از آمپول کمپلکس پپتیدی لیفت و سفیدکننده به همراه مخلوط ترموژل (2/1) + CoolRelief (2/1) &ndash; ماساژ به مدت 10 دقیقه و سپس شستشو</h3>
                <p>کمپلکس پپتیدی لیفت و سفید کننده یک ترکیب موثر و بسیار غلیظ از پپتیدها است که برای دستیابی به اثر قابل توجهی در ثبات و سفیدی پوست استفاده می شود.</p>
                <p>- مسیرهای بازسازی متعدد در درم را فعال می کند.</p>
                <p>- عمق چین و چروک را به میزان قابل توجهی کاهش می دهد.</p>
                <p>- سطح رطوبت و تورم پوست را بهبود می بخشد.</p>
                <p>- سطح تغذیه و خاصیت ارتجاعی پوست را افزایش می دهد.</p>
                <p>- اثر سفید کنندگی پوست را فراهم می کند.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>Palmitoyl Tripeptide-38 (Matrixyl Synthe'6TM): این فرمول حاوی غلظت بسیار بالایی از Matrixyl Synthe'6 است و یک تقویت کننده سفت کننده پوست در نظر گرفته می شود. این فرمول یک پپتید تقلیدی ماتریکین است که با سنتز ۶ جزء ساختاری اصلی درم، باعث تسریع روند پر کردن چین و چروک می&zwnj;شود. مواد سازنده را با تحریک سنتز شش مولکول اصلی - اسید هیالورونیک، کلاژن I، III و IV، لامینین ها و فیبرونکتین فراهم می&zwnj;کند و به طور قابل توجهی پوست را سفت می&zwnj;کند و چین و چروک ها را پر می&zwnj;کند.</li>
                    <li>سیکلوتتراپپتید-۲۴ آمینو سیکلوهگزان کربوکسیلات: یک ساختار حلقوی منحصر به فرد که با چین و چروک و خطوط ریز مقابله می&zwnj;کند. طراحی منحصر به فرد آن به عنوان یک پپتید هوشمند و انتخابی، فرآیندهای طبیعی ارتباط و ترمیم پوست را تقلید می کند.</li>
                    <li>اُلیگوپپتید-68: عامل سفید کننده نوآورانه و منحصر به فرد که ظاهر لکه های تیره و تغییر رنگ پوست را ناپیداتر می&zwnj;کند.</li>
                    <li>عصاره Swertia Chirata: یک ماده تشکیل شده از برگ&zwnj;های جنتیان هندی است. این عصاره سلول های بنیادی مشتق شده از چربی را فعال کرده، تولید فاکتورهای رشد کراتینوسیت را تقویت می&zwnj;کند و قادر به تحریک تکثیر کراتینوسیت&zwnj;ها برای بازسازی اپیدرم نازک و بهبود ظاهر پوست چروکیده است. همچنین باعث بهبود ظاهر چین و چروک&zwnj;های عمودی و کاهش چین و چروک&zwnj;های لب می&zwnj;شود.</li>
                    <li>هیالورونات سدیم: اسید هیالورونیک با وزن مولکولی بالا با خلوص بسیار بالا که آبرسانی عمیق را فراهم می کند. خاصیت رطوبت سنجی قوی هیالورونات سدیم باعث حفظ آب در سطح پوست می شود و نقش مخزن آب را ایفا می کند.</li>
                    <li>سدیم PCA: یک جزء طبیعی NMF (عامل مرطوب کننده طبیعی). سدیم PCA بسیار جاذب آب است و می&zwnj;تواند چندین برابر وزن خود را در آب نگه دارد، بنابراین به کاهش از دست دادن رطوبت از طریق تبخیر (از دست دادن آب ترانس اپیدرمی) کمک می&zwnj;کند.</li>
                </ul>
                <p>&nbsp;</p>
                <p>ماسک ProLift &ndash; 15 دقیقه</p>
                <p>ماسک ProLift یک محصول مراقبت از پوست ضد پیری پیشرفته است که بلافاصله پوست را لیفت و سفت می کند. نتایج سریع ضد چین و چروک را ارائه می دهد و در عین حال پوست را آبرسانی، نرم و آرام می کند. تنها ۵ دقیقه پس از استفاده از آن، پوست شما شاداب به نظر می رسد.</p>
                <p>عناصر فعال</p>
                <ul>
                    <li>استیل دی پپتید-۱ ستیل استر: یک ماده فعال پپتیدی ملایم و آرامش بخش در لوازم آرایشی است. این اولین ماده در نوع خود است که به پوست سیگنال کاهش تنش می&zwnj;دهد. ترشح اندورفین را تحریک می&zwnj;کند و اثری خوشایند بر روی پوست ایجاد می&zwnj;کند. همچنین از انقباضات ماهیچه ای که باعث بروز چین و چروک و خطوط می شود جلوگیری می&zwnj;کند.</li>
                    <li>پروتئین کنجد هیدرولیز شده PG - پروپیل متیل سیلاندیول: یک ماده صاف کننده نسل جدید است. حاوی یک عامل سفت کننده فوری جدید است که بلافاصله پوست را صاف و لیفت می&zwnj;کند. علاوه بر یک سفت کننده سریع، این ماده پوست را عمیقاً آبرسانی می&zwnj;کند. در یک لحظه خطوط ریز و چین و چروک ها را صاف می&zwnj;کند و در عین حال پوست را کاملا مرطوب می&zwnj;کند.</li>
                    <li>آلانتوئین: یک ماده فعال است که از پوست محافظت می&zwnj;کند و آن را تسکین می&zwnj;دهد و به طور طبیعی روند بهبود پوست را تحریک می کند.</li>
                    <li>شی باتر: حاوی غلظت بالایی از ویتامین&zwnj;ها و اسیدهای چرب طبیعی است که آن را به طور باورنکردنی مقوی و مرطوب کننده برای پوست می کند.</li>
                </ul>
                <p>&nbsp;</p>
                <h4>سرم فیلر عمیق</h4>
                <p>این سرم ضد چین و چروک، تراکم، استحکام و قابلیت ارتجاعی پوست را بهبود می&zwnj;بخشد و در عین حال آبرسانی عمیقی دارد. از ساخت و جوانسازی پوست که از طریق روش&zwnj;هایی مانند لایه برداری، میکرونیدلینگ، RF و لیزر انجام می شود، پشتیبانی می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>MG6P: این سرم حاوی ترکیبات فعالی است که با همکاری یکدیگر باعث افزایش کلاژن و الاستین در پوست می&zwnj;شوند. MG6P به سلول های پوست انرژی می&zwnj;دهد تا تولید این پروتئین&zwnj;های کلیدی را افزایش دهد. اسیدهای آمینه پرولین و لیزین بلوک&zwnj;های سازنده برای ساخت کلاژن و الاستین هستند. مس به تثبیت ساختارهای کلاژن و الاستین کمک می&zwnj;کند. این مواد با هم پوست را تحریک می&zwnj;کنند+ تا فیبرهای کلاژن و الاستین بیشتری را سنتز کند که استحکام، کشش و اثرات ضد پیری را بهبود می&zwnj;بخشد.</p>
                <p>Tetradecyl Aminobutyroylvalylaminobutyric Urea Trifluoroacetate (TAUT): یک تری پپتید که باعث تحریک پوست برای تولید اسید هیالورونیک می&zwnj;شود. این منجر به اثرات بازسازی قابل مشاهده پوست و افزایش استحکام می&zwnj;گردد.</p>
                <p>زایلینوم (چای سیاه): زایلینوم از تخمیر چای سیاه تولید می شود. اثرات محافظتی بر روی ماتریکس خارج سلولی در پوست ایجاد می&zwnj;کند. زایلینوم آسیب گلیکوزیشن به فیبرهای الاستین را کاهش می&zwnj;دهد و باعث نرم&zwnj;تر شدن پوست می&zwnj;شود.</p>
                <p>سدیم هیالورونات: سدیم هیالورونات یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که پوست را عمیقا آبرسانی می&zwnj;کند. خواص رطوبت&zwnj;گیری آن به آن اجازه می&zwnj;دهد تا آب را در سطح پوست جذب و حفظ کند و به عنوان یک مخزن رطوبت عمل کند.</p>
                <p>عصاره گل آرنیکا مونتانا: این عصاره دارای خواص ضد التهابی و تسکین دهنده است. این به کاهش درد و تورم مرتبط با روش&zwnj;های پوستی کمک می&zwnj;کند.</p>
                <p>سدیم PCA: سدیم PCA یک مرطوب&zwnj;کننده و جزء طبیعی عامل مرطوب کننده طبیعی پوست است. آب را جذب و حفظ می&zwnj;کند و به کاهش از دست دادن رطوبت از پوست کمک می&zwnj;کند.</p>
                <h3>LumiLac</h3>
                <p>LumiLac به طور منحصر به فرد جوانسازی پوست را تسریع می&zwnj;کند و بافت را اصلاح می&zwnj;کند و در عین حال رنگ پوست و آبرسانی را بهبود می&zwnj;بخشد. اسید لاکتیک ۱۰ درصد با ShieldSkin به همه انواع پوست اجازه می&zwnj;دهد تا از این کرم AHA در تمام طول سال بدون مشکل استفاده کنند. این کرم بافتی سبک و بدون چربی دارد که به سرعت جذب پوست می&zwnj;شود و برای انواع پوست مناسب است.</p>
                <p>فواید:</p>
                <p>- ظاهر چین و چروک و خطوط ریز را کاهش می&zwnj;دهد.</p>
                <p>- باعث جابجایی سلولی و لایه برداری می&zwnj;شود.</p>
                <p>- آسیب ناشی از نور خورشید را ترمیم می&zwnj;کند و از ایجاد توده جلوگیری می&zwnj;کند.</p>
                <p>- تراکم و خاصیت ارتجاعی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- به کاهش تغییر رنگ و هایپرپیگمانتاسیون کمک می&zwnj;کند.</p>
                <p>- برای استفاده در اطراف ناحیه ظریف چشم بی&zwnj;خطر است.</p>
                <p>- تداخلی با سایر داروهای آرایشی یا پزشکی ندارد.</p>
                <h3>کرم فیلر عمیق</h3>
                <p>این کرم ضد چروک حاوی چندین ترکیب ضد پیری است که اثرات پرکننده چین و چروک را ایجاد می&zwnj;کند. چین و چروک&zwnj;ها را کاهش می&zwnj;دهد و در عین حال پوست را عمیقا آبرسانی می&zwnj;کند و پوست را تقویت می&zwnj;کند تا پوستی یکدست، صاف و الاستیک داشته باشید.</p>
                <h4>عناصر فعال</h4>
                <p>پالمیتویل هگزاپپتید-:۱۴ یک پپتید ضد پیری است که تولید کلاژن، فعالیت فیبروبلاست و خاصیت ارتجاعی را تحریک می&zwnj;کند و در عین حال آنزیم&zwnj;های MMP را مهار می&zwnj;کند. این منجر به کاهش خطوط ریز، چین و چروک و بهبود خاصیت ارتجاعی پوست می&zwnj;شود.</p>
                <p>Palmitoyl Tripeptide-38 (MATRIXYL synthe'6TM): یک پپتید تقلیدی ماتریکینی است که تولید شش مولکول ماتریکس مختلف را در پوست تحریک می کند که به پر کردن چین و چروک ها کمک می کند.</p>
                <p>پالمیتویل ایزولوسین یک اسید آمینه ضروری است که از ترمیم و التیام بافت پشتیبانی می&zwnj;کند. این ماده با تحریک سلول&zwnj;های چربی در پوست، به عنوان یک لیپوفیلر عمل می&zwnj;کند و از پیری آن&zwnj;ها به سلول&zwnj;های MAD ناکارآمد جلوگیری می کند. همچنین تعامل بین فیبروبلاست ها، سلول های تولید کننده کلاژن و ماتریکس پوستی را افزایش می دهد. پالمیتویل ایزولوسین دارای خواص آنتی اکسیدانی نیز هست.</p>
                <p>عصاره تخمیر آلتروموناس: یک ماده بسیار مرطوب کننده است. این عصاره اسید هیالورونیک و تولید چربی را در سلول&zwnj;های پیر پوست بازیابی می&zwnj;کند و از عملکرد پوست پشتیبانی می&zwnj;کند. این آبرسانی و یکپارچگی پوست را بهبود می بخشد.</p>
                <p>عصاره Swertia Chirata: یک ماده ابتکاری است که از برگ های جنتیان هندی به دست می&zwnj;آید. برای کمک به جوانسازی و ضخیم شدن پوست فرموله شده است. مطالعات نشان داده است که می تواند چین و چروک های عمودی اطراف لب را کاهش دهد. این عصاره گیاهی به احیای پوست پیر و بهبود بافت و ظاهر اطراف ناحیه ظریف لب کمک می کند.</p>
                <p>هیالورونات سدیم: یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که آبرسانی عمیقی به پوست می&zwnj;دهد. هیالورونات سدیم به دلیل خاصیت رطوبت&zwnj;گیری یا جذب آب قوی می تواند آب را در سطح پوست نگه دارد و به عنوان یک مخزن رطوبت عمل می&zwnj;کند. این به آن اجازه می&zwnj;دهد تا پوست را برای مدت طولانی&zwnj;تری هیدراته نگه دارد.</p>
                <h3>ضد آفتاب VitaShield</h3>
                <p>ضد آفتاب VitaShield نسل جدید ضد آفتاب ضد پیری با فیلتر UV کپسوله شده و ویتامین&zwnj;های B, C, E است. این ضد آفتاب به طور موثر از پوست در برابر اشعه&zwnj;های UVA/UVB و IR ، اشعه&zwnj;هایی که به روش&zwnj;های مختلف باعث آسیب پوست می&zwnj;شوند، محافظت می&zwnj;کند.</p>
                <p>- در برابر اشعه های UVA/UVB/IR/نور مرئی و رادیکال&zwnj;های آزاد محافظت می&zwnj;کند.</p>
                <p>- مقاومت سلول را در شرایط تنش دمایی افزایش می&zwnj;دهد.</p>
                <p>- آبرسانی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- از آسیب ماکرومولکولی ماتریکس جلوگیری می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>UV-Pearls&reg; کپسوله شده: معروف به &laquo;عینک آفتابی&raquo; برای پوست. در حالی که فیلتر UV از تماس مستقیم آن با سطح پوست جلوگیری می کند، از پوست محافظت می&zwnj;کند. UV-Pearls کپسوله شده در برابر تنش دمایی مقاوم هستند.</p>
                <p>تیتانیوم دی اکسید (نانو): یک ضد آفتاب بسیار موثر است. این ماده از پوست در برابر طیف کامل تابش خورشیدی - UVA، UVB، نور مرئی و اشعه مادون قرمز - محافظت می&zwnj;کند تا حتی برای انواع پوست های حساس نیز دفاع کاملی در برابر آفتاب ایجاد کند. تیتانیوم دی اکسید محافظت قوی در برابر پیری و چین و چروک ایجاد می&zwnj;کند و در عین حال رنگ غیر یکسان پوست را همسان می&zwnj;کند.</p>
                <p>اکتوئین: یک ماده چند منظوره با فواید پوستی متعدد است. اکتوئین می&zwnj;تواند تولید سلول&zwnj;های آفتاب سوختگی و آسیب های ناشی از اشعه UVA را کاهش دهد. اکتوئین همچنین به عنوان یک مخزن رطوبت برای جلوگیری از از دست دادن آب در انواع پوست های خشک و حساس مانند درماتیت آتوپیک عمل می&zwnj;کند. این ماده با حفظ آب بدن، به کاهش علائم خشکی کمک می&zwnj;کند.</p>
                <p>ویتامین&zwnj;های C و E: در نقش آنتی اکسیدان</p>
                <p>نیاسینامید: این ماده که به عنوان ویتامین B3 نیز شناخته می شود، یک ترکیب روشن کننده پوست است که از انتقال ملانوزوم ها از ملانوسیت ها به کراتینوسیت ها جلوگیری می کند. ملانوزوم ها اندامک هایی هستند که حاوی رنگدانه ملانین هستند. نیاسینامید با مهار انتقال آنها به کراتینوسیت ها، رنگدانه های پوست را کاهش می دهد و اثر سفید کنندگی دارد. این جزء سیستم های بیولوژیکی مختلف و یک ماده مغذی ضروری برای بدن است.</p>
                <p>&nbsp;</p>

            </Box>}
            {value === 1 && <Box p={3}>

                <p><strong>صبح</strong></p>
                <h3>C-Purity foam</h3>
                <h3>کمپلکس پپتیدی لیفت و سفید کننده</h3>
                <p>کمپلکس پپتیدی لیفت و سفید کننده یک ترکیب موثر و بسیار غلیظ از پپتیدها است که برای دستیابی به اثر قابل توجهی در ثبات و سفیدی پوست استفاده می شود.</p>
                <p>- مسیرهای بازسازی متعدد در درم را فعال می کند.</p>
                <p>- عمق چین و چروک را به میزان قابل توجهی کاهش می دهد.</p>
                <p>- سطح رطوبت و تورم پوست را بهبود می بخشد.</p>
                <p>- سطح تغذیه و خاصیت ارتجاعی پوست را افزایش می دهد.</p>
                <p>- اثر سفید کنندگی پوست را فراهم می کند.</p>
                <h4>عناصر فعال</h4>
                <ul>
                    <li>Palmitoyl Tripeptide-38 (Matrixyl Synthe'6TM): این فرمول حاوی غلظت بسیار بالایی از Matrixyl Synthe'6 است و یک تقویت کننده سفت کننده پوست در نظر گرفته می شود. این فرمول یک پپتید تقلیدی ماتریکین است که با سنتز ۶ جزء ساختاری اصلی درم، باعث تسریع روند پر کردن چین و چروک می&zwnj;شود. مواد سازنده را با تحریک سنتز شش مولکول اصلی - اسید هیالورونیک، کلاژن I، III و IV، لامینین ها و فیبرونکتین فراهم می&zwnj;کند و به طور قابل توجهی پوست را سفت می&zwnj;کند و چین و چروک ها را پر می&zwnj;کند.</li>
                    <li>سیکلوتتراپپتید-۲۴ آمینو سیکلوهگزان کربوکسیلات: یک ساختار حلقوی منحصر به فرد که با چین و چروک و خطوط ریز مقابله می&zwnj;کند. طراحی منحصر به فرد آن به عنوان یک پپتید هوشمند و انتخابی، فرآیندهای طبیعی ارتباط و ترمیم پوست را تقلید می کند.</li>
                    <li>اُلیگوپپتید-68: عامل سفید کننده نوآورانه و منحصر به فرد که ظاهر لکه های تیره و تغییر رنگ پوست را ناپیداتر می&zwnj;کند.</li>
                    <li>عصاره Swertia Chirata: یک ماده تشکیل شده از برگ&zwnj;های جنتیان هندی است. این عصاره سلول های بنیادی مشتق شده از چربی را فعال کرده، تولید فاکتورهای رشد کراتینوسیت را تقویت می&zwnj;کند و قادر به تحریک تکثیر کراتینوسیت&zwnj;ها برای بازسازی اپیدرم نازک و بهبود ظاهر پوست چروکیده است. همچنین باعث بهبود ظاهر چین و چروک&zwnj;های عمودی و کاهش چین و چروک&zwnj;های لب می&zwnj;شود.</li>
                    <li>هیالورونات سدیم: اسید هیالورونیک با وزن مولکولی بالا با خلوص بسیار بالا که آبرسانی عمیق را فراهم می کند. خاصیت رطوبت سنجی قوی هیالورونات سدیم باعث حفظ آب در سطح پوست می شود و نقش مخزن آب را ایفا می کند.</li>
                    <li>سدیم PCA: یک جزء طبیعی NMF (عامل مرطوب کننده طبیعی). سدیم PCA بسیار جاذب آب است و می&zwnj;تواند چندین برابر وزن خود را در آب نگه دارد، بنابراین به کاهش از دست دادن رطوبت از طریق تبخیر (از دست دادن آب ترانس اپیدرمی) کمک می&zwnj;کند.</li>
                </ul>
                <h4>سرم فیلر عمیق</h4>
                <p>این سرم ضد چین و چروک، تراکم، استحکام و قابلیت ارتجاعی پوست را بهبود می&zwnj;بخشد و در عین حال آبرسانی عمیقی دارد. از ساخت و جوانسازی پوست که از طریق روش&zwnj;هایی مانند لایه برداری، میکرونیدلینگ، RF و لیزر انجام می شود، پشتیبانی می&zwnj;کند.</p>
                <h4>عناصر فعال</h4>
                <p>MG6P: این سرم حاوی ترکیبات فعالی است که با همکاری یکدیگر باعث افزایش کلاژن و الاستین در پوست می&zwnj;شوند. MG6P به سلول های پوست انرژی می&zwnj;دهد تا تولید این پروتئین&zwnj;های کلیدی را افزایش دهد. اسیدهای آمینه پرولین و لیزین بلوک&zwnj;های سازنده برای ساخت کلاژن و الاستین هستند. مس به تثبیت ساختارهای کلاژن و الاستین کمک می&zwnj;کند. این مواد با هم پوست را تحریک می&zwnj;کنند تا فیبرهای کلاژن و الاستین بیشتری را سنتز کند که استحکام، کشش و اثرات ضد پیری را بهبود می&zwnj;بخشد.</p>
                <p>Tetradecyl Aminobutyroylvalylaminobutyric Urea Trifluoroacetate (TAUT): یک تری پپتید که باعث تحریک پوست برای تولید اسید هیالورونیک می&zwnj;شود. این منجر به اثرات بازسازی قابل مشاهده پوست و افزایش استحکام می&zwnj;گردد.</p>
                <p>زایلینوم (چای سیاه): زایلینوم از تخمیر چای سیاه تولید می شود. اثرات محافظتی بر روی ماتریکس خارج سلولی در پوست ایجاد می&zwnj;کند. زایلینوم آسیب گلیکوزیشن به فیبرهای الاستین را کاهش می&zwnj;دهد و باعث نرم&zwnj;تر شدن پوست می&zwnj;شود.</p>
                <p>سدیم هیالورونات: سدیم هیالورونات یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که پوست را عمیقا آبرسانی می&zwnj;کند. خواص رطوبت&zwnj;گیری آن به آن اجازه می&zwnj;دهد تا آب را در سطح پوست جذب و حفظ کند و به عنوان یک مخزن رطوبت عمل کند.</p>
                <p>عصاره گل آرنیکا مونتانا: این عصاره دارای خواص ضد التهابی و تسکین دهنده است. این به کاهش درد و تورم مرتبط با روش&zwnj;های پوستی کمک می&zwnj;کند.</p>
                <p>سدیم PCA: سدیم PCA یک مرطوب&zwnj;کننده و جزء طبیعی عامل مرطوب کننده طبیعی پوست است. آب را جذب و حفظ می&zwnj;کند و به کاهش از دست دادن رطوبت از پوست کمک می&zwnj;کند.</p>
                <h3>LumiLac/ IntenseGel</h3>
                <p>LumiLac به طور منحصر به فرد جوانسازی پوست را تسریع می&zwnj;کند و بافت را اصلاح می&zwnj;کند و در عین حال رنگ پوست و آبرسانی را بهبود می&zwnj;بخشد. اسید لاکتیک ۱۰ درصد با ShieldSkin به همه انواع پوست اجازه می&zwnj;دهد تا از این کرم AHA در تمام طول سال بدون مشکل استفاده کنند. این کرم بافتی سبک و بدون چربی دارد که به سرعت جذب پوست می&zwnj;شود و برای انواع پوست مناسب است.</p>
                <p>فواید:</p>
                <p>- ظاهر چین و چروک و خطوط ریز را کاهش می&zwnj;دهد.</p>
                <p>- باعث جابجایی سلولی و لایه برداری می&zwnj;شود.</p>
                <p>- آسیب ناشی از نور خورشید را ترمیم می&zwnj;کند و از ایجاد توده جلوگیری می&zwnj;کند.</p>
                <p>- تراکم و خاصیت ارتجاعی پوست را بهبود می&zwnj;بخشد.</p>
                <p>- به کاهش تغییر رنگ و هایپرپیگمانتاسیون کمک می&zwnj;کند.</p>
                <p>- برای استفاده در اطراف ناحیه ظریف چشم بی&zwnj;خطر است.</p>
                <p>- تداخلی با سایر داروهای آرایشی یا پزشکی ندارد.</p>
                <h3>کرم فیلر عمیق</h3>
                <p>این کرم ضد چروک حاوی چندین ترکیب ضد پیری است که اثرات پرکننده چین و چروک را ایجاد می&zwnj;کند. چین و چروک&zwnj;ها را کاهش می&zwnj;دهد و در عین حال پوست را عمیقا آبرسانی می&zwnj;کند و پوست را تقویت می&zwnj;کند تا پوستی یکدست، صاف و الاستیک داشته باشید.</p>
                <h4>عناصر فعال</h4>
                <p>پالمیتویل هگزاپپتید-:۱۴ یک پپتید ضد پیری است که تولید کلاژن، فعالیت فیبروبلاست و خاصیت ارتجاعی را تحریک می&zwnj;کند و در عین حال آنزیم&zwnj;های MMP را مهار می&zwnj;کند. این منجر به کاهش خطوط ریز، چین و چروک و بهبود خاصیت ارتجاعی پوست می&zwnj;شود.</p>
                <p>Palmitoyl Tripeptide-38 (MATRIXYL synthe'6TM): یک پپتید تقلیدی ماتریکینی است که تولید شش مولکول ماتریکس مختلف را در پوست تحریک می کند که به پر کردن چین و چروک ها کمک می کند.</p>
                <p>پالمیتویل ایزولوسین یک اسید آمینه ضروری است که از ترمیم و التیام بافت پشتیبانی می&zwnj;کند. این ماده با تحریک سلول&zwnj;های چربی در پوست، به عنوان یک لیپوفیلر عمل می&zwnj;کند و از پیری آن&zwnj;ها به سلول&zwnj;های MAD ناکارآمد جلوگیری می کند. همچنین تعامل بین فیبروبلاست ها، سلول های تولید کننده کلاژن و ماتریکس پوستی را افزایش می دهد. پالمیتویل ایزولوسین دارای خواص آنتی اکسیدانی نیز هست.</p>
                <p>عصاره تخمیر آلتروموناس: یک ماده بسیار مرطوب کننده است. این عصاره اسید هیالورونیک و تولید چربی را در سلول&zwnj;های پیر پوست بازیابی می&zwnj;کند و از عملکرد پوست پشتیبانی می&zwnj;کند. این آبرسانی و یکپارچگی پوست را بهبود می بخشد.</p>
                <p>عصاره Swertia Chirata: یک ماده ابتکاری است که از برگ های جنتیان هندی به دست می&zwnj;آید. برای کمک به جوانسازی و ضخیم شدن پوست فرموله شده است. مطالعات نشان داده است که می تواند چین و چروک های عمودی اطراف لب را کاهش دهد. این عصاره گیاهی به احیای پوست پیر و بهبود بافت و ظاهر اطراف ناحیه ظریف لب کمک می کند.</p>
                <p>هیالورونات سدیم: یک اسید هیالورونیک با خلوص بالا و وزن مولکولی بالا است که آبرسانی عمیقی به پوست می&zwnj;دهد. هیالورونات سدیم به دلیل خاصیت رطوبت&zwnj;گیری یا جذب آب قوی می تواند آب را در سطح پوست نگه دارد و به عنوان یک مخزن رطوبت عمل می&zwnj;کند. این به آن اجازه می&zwnj;دهد تا پوست را برای مدت طولانی&zwnj;تری هیدراته نگه دارد.</p>
                <h3>ضد آفتاب VitaShield</h3>
                <p><strong>عصر</strong></p>
                <h3>C-Purity Foam</h3>
                <h3>کمپلکس پپتیدی لیفت و سفید کننده</h3>
                <p>RetaNew Delicate 0.3%</p>
                <p>RetaNew یک تقویت کننده ضد پیری و جوان کننده پوست است که خطوط ریز را سفت، روشن و کاهش می دهد تا بافت و رنگ پوستی صاف و یکنواخت داشته باشید. این محصول در سه غلظت برای پاسخگویی به نیازهای مختلف ارائه می شود:</p>
                <p>RetaNew Delicate 0.3% برای پوست های حساس، RetaNew Intense 1% و RetaNew Plus 1.6% با SheildSkin برای بالاترین قدرت با محافظت در برابر تحریک.</p>
                <p>عناصر فعال:</p>
                <p>رتینول: یک ماده ضد پیری قوی است که بافت پوست را صاف می&zwnj;کند، خطوط ریز را کاهش می&zwnj;دهد، با پیری پوست مبارزه می&zwnj;کند، پوست را سفید می&zwnj;کند، تولید چربی را متعادل می&zwnj;کند، ساختار پوست را بازسازی می&zwnj;کند و پوست را سفت می&zwnj;کند. استفاده منظم آن، فرآیندهای پیری را معکوس می&zwnj;کند و جابجایی سلولی را افزایش می&zwnj;دهد. در درمان آسیب ناشی از خورشید با استفاده مداوم موثر است. ShieldSkin حساسیت به نور خورشید ناشی از رتینول را به حداقل می&zwnj;رساند.</p>
                <p>Oligopeptide-68: یک ماده سفید کننده خلاقانه است که به کاهش ظاهر لکه های تیره و تغییر رنگ پوست کمک می کند. با کاهش هایپرپیگمانتاسیون قابل مشاهده، رنگ پوست را روشن و رنگ پوست را یکدست می&zwnj;کند.</p>
                <p>عصاره Leontopodium alpinum : از گل سپیدگوهر (Edelweiss)گرفته شده است. حاوی اسید لئونتوپودیک است که دارای خواص آنتی اکسیدانی قوی و مهار رادیکال های آزاد است. این عصاره پروتئین هایی را تقویت می کند که سد محافظ پوست در برابر آسیب اشعه ماوراء بنفش و عوامل استرس&zwnj;زای محیطی را تقویت می&zwnj;کند. این به ایجاد یک سپر سلولی برای جلوگیری از پیری زودرس کمک می&zwnj;کند.</p>
                <p>متیل پرفلوروبوتیل اتر (امولسیون پرفلوئوروکربن): یک سیستم تحویل اکسیژن غیر واکنشی است. از نظر بالینی نشان داده شده است که به عنوان یک منبع اکسیژن مصنوعی عمل می&zwnj;کند که می&zwnj;تواند اکسیژن رسانی بافت را بهبود بخشد. با افزایش سطح اکسیژن در پوست، به درمان پوست آسیب دیده و معکوس کردن علائم پیری کمک می&zwnj;کند. این ماده قوی اکسیژن را بدون گونه&zwnj;های واکنش&zwnj;پذیر برای احیای رنگ پوست فراهم می&zwnj;کند.</p>
                <h3>LumiLac/ IntenseGel</h3>
                <h3>کرم فیلر عمیق</h3>
                <p><strong>هفتگی</strong></p>
                <h3>PuriMask تا دو بار در هفته</h3>
                <p>PuriMask یک ماسک تصفیه کننده برای درمان پوست&zwnj;های دارای اختلال و چرب است. PuriMask ضد عفونی می کند، قرمزی پوست را تسکین می دهد، با جوش زدن مبارزه می&zwnj;کند و تکثیر باکتری ها را کاهش می دهد.</p>
                <h4>عناصر فعال</h4>
                <p>اولئانولیک اسید: یک مهارکننده قوی فرآیندهای التهابی سلولی و محافظ اصلی سلول ها در برابر استرس اکسیداتیو است. ۵-&alpha;ردوکتاز برای مبارزه با هایپرسبوریا و کاهش تأثیر تستوسترون بر روی پوست مستعد آکنه.</p>
                <ul>
                    <li>Nordihydroguaiaretic Acid (NDGA) یک تنظیم&zwnj;کننده رشد سلولی است که هایپرکراتوز و التهاب را مهار می&zwnj;کند. همچنین به کنترل رشد باکتری کمک می&zwnj;کند.</li>
                    <li>عصاره بره موم: به دلیل خواص ضد باکتریایی و ضد التهابی که دارد روند بهبود زخم را تسریع می کند.</li>
                    <li>عصاره مریم گلی دارویی: از بروز عفونت های پوستی جلوگیری می کند.</li>
                    <li>آلانتوئین: عیوب را التیام می بخشد.</li>
                    <li>روغن برگ اکالیپتوس: عفونت های پوستی را درمان می&zwnj;کند.</li>
                    <li>گوگرد: باکتری ها و چربی را از بین می&zwnj;برد که به نوبه خود باعث کاهش افزایش منافذ پوستی می&zwnj;شود.</li>
                </ul>
                <h3>SunShield - پوست&zwnj;های دارای اختلال و چرب (در طول روز با هر بار قرار گرفتن در معرض نور خورشید)</h3>
                <p>پودر معدنی ضد آفتاب با طیف وسیع فرموله شده برای پاسخگویی به نیازهای پوست&zwnj;های دارای اختلال و چرب که برای استفاده روزمره طراحی شده است، پودر معدنی SunShield SPF 50 محافظت فوری و بهینه را در برابر اشعه های مضر UVA، UVB و IR ارائه می&zwnj;کند. این پودر با خواص ضد التهابی خود، راه حل ایده آلی برای محافظت در برابر آفتاب برای پوست&zwnj;های دارای اختلال و چرب است. همچنین به جلوگیری از آسیب&zwnj;های ناشی از نور خورشید مانند پیری و اختلالات رنگدانه&zwnj;ای کمک می&zwnj;کند.</p>
                <h4>مزایای SunShield:</h4>
                <ul>
                    <li>از اشعه های مضر UVA/UVB/IR و رادیکال های آزاد محافظت می&zwnj;کند.</li>
                    <li>خاصیت ضد التهابی ارائه می&zwnj;دهد.</li>
                    <li>به جلوگیری از آسیب های ناشی از نور خورشید، از جمله پیری و اختلالات رنگدانه&zwnj;ای کمک می&zwnj;کند.</li>
                    <li>بسته بندی با کاربری آسان امکان کنترل مقدار پودر را در حین استفاده فراهم می&zwnj;کند.</li>
                    <li>بسته بندی سازگار با محیط زیست - برس و ظرف پودر قابل تعویض</li>
                    <li>موجود در دو رنگ متفاوت</li>
                    <li>غیر حساس به گرما</li>
                    <li>پوشش مات، پوشش بدون درز، اثر بازتابی</li>
                </ul>
                <p>&nbsp;</p>
            </Box>}
        </>
    );
};

export default OSNWTreatment;