import React from 'react';
import '../assets/css/admin-background.css';
export function AdminBackground(props) {
    return (
        <section>
            <div className='air air1'></div>
            <div className='air air2'></div>
            <div className='air air3'></div>
            <div className='air air4'></div>
        </section>
    );
}
